export const DrawerFormFields = () => {

  const drawerFormFields = [
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'admin_id', id: 'admin_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'text', name: 'Name', id: 'name', required: true },
    { type: 'number', name: `Default amount`, id: 'default_amount', required: true, step:'.01' },
    { type: 'select', name: 'Count as', id: 'count_as', formdatafield: 'count_as', required: true },
    { type: 'hidden', name: 'Device', id: 'device_id', formdatafield: 'device_id', required: false },
    { type: 'hidden', name: 'Employee', id: 'employee_id', formdatafield: 'employee_id', required: false },
  ]

  return drawerFormFields
}