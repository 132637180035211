import { components } from 'react-select'
import { useState } from 'react'
import { ReactComponent as MinusIcon } from '../static/icons/minus.svg'
import { ReactComponent as PlusIcon } from '../static/icons/plus.svg'

export default function MultiValueContainer(props) {
  const {selectedViews} = props.selectProps
  const {durationUpdate} = props.selectProps
  const [duration, setDuration] = useState(selectedViews.find(view => view.id === props.data.value).seconds)
  const [minusColor, setMinusColor] = useState('#000000')
  const [plusColor, setPlusColor] = useState('#000000')
  return (
    <components.MultiValueContainer {...props} innerProps={{...props.innerProps}}>
      <style>{/*removes the spin buttons from the number input*/}{' input::-webkit-outer-spin-button, input::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0; } input[type=number] { -moz-appearance: textfield; }'}</style>
      {props.children}
      <div style={{gridRow: '2', gridColumn: 'span 2', display: 'inline-flex', alignItems: 'center', justifyContent:'space-evenly'}}>
        <MinusIcon 
          width='20px'
          color={minusColor}
          onPointerDown= {event => {
            event.stopPropagation();
            setMinusColor('#999999')
            let newDuration = parseInt(duration)
            if (isNaN(newDuration)) {
              newDuration = 1
              setDuration(newDuration)
              durationUpdate({duration: newDuration, viewId: props.data.value, viewsList: props.selectProps.name})
            } else if (newDuration > 1) {
              setDuration(newDuration-1)
              durationUpdate({duration: newDuration-1, viewId: props.data.value, viewsList: props.selectProps.name})
            }
          }}
          onPointerUp= {() => {
            setMinusColor('#777777')
          }}
          onMouseEnter= {() => {
            setMinusColor('#777777')
          }}
          onMouseLeave= {() => {
            setMinusColor('#000000')
          }}
        />
        <input 
          id='duration'
          type='number'
          value={duration}
          min= {1}
          onClick={event => {
            event.target.focus();
            event.target.select();
          }}
          onPointerDown={event => {
            event.stopPropagation();
          }}
          onChange={event => {
            setDuration(event.target.value)
            durationUpdate({duration: event.target.value, viewId: props.data.value, viewsList: props.selectProps.name})
          }}
          onKeyDown={event => {
            event.stopPropagation()
          }}
          style={{
            border:'0',
            background:'0',
            textAlign:'center',
            width:'70px',
            fontWeight: '500',
            lineHeight: '1.2',
            fontSize: '2rem'
          }}
        />
        <PlusIcon 
          width='20px'
          color={plusColor}
          onPointerDown= {event => {
            event.stopPropagation();
            setPlusColor('#999999')
            let newDuration = parseInt(duration)
            if (isNaN(newDuration)) {
              newDuration = 0
            }
            setDuration(newDuration+1)
            durationUpdate({duration: newDuration+1, viewId: props.data.value, viewsList: props.selectProps.name})
          }}
          onPointerUp= {() => {
            setPlusColor('#777777')
          }}
          onMouseEnter= {() => {
            setPlusColor('#777777')
          }}
          onMouseLeave= {() => {
            setPlusColor('#000000')
          }}
        />
      </div>
    </components.MultiValueContainer>
  )
}