import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { getCountPageSize, getCountPagination, getCountsAll, getDrawerPageSize, getDrawerPagination, getDrawersAll, getHashDrawersAll, setCountsAll, setDrawersAll } from "../../redux/dataSlices/cashcountSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import { getHashAdministrationsAll } from "../../redux/dataSlices/administrationSlice";
import { getHashLocationsAll } from "../../redux/dataSlices/locationSlice";
import DrawersTable from "../../components/drawerTable";
import CountTable from "../../components/countTable";
import { getHashEmployeesAll, setEmployeesAll } from "../../redux/dataSlices/employeeSlice";
import { setUsersAll } from "../../redux/dataSlices/userSlice";
import GetCashCountDrawerAll from "../../actablueAPI/cashcount/GetCashCountDrawerAll";
import GetCashCountCountAll from "../../actablueAPI/cashcount/GetCashCountCountAll";
import GetEmployees from "../../actablueAPI/employee/GetEmployees";
import GetUsersList from "../../actablueAPI/serials/GetUsersList";
import useHandleError from "../../customhooks/useHandleError";
import SearchAndMultiFilterSingleSelect from "../../components/searchAndMultiFilterSingleSelectField";

const Cashcount = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const hash_admins_all = useSelector(getHashAdministrationsAll)
  const hash_locations_all = useSelector(getHashLocationsAll)
  const hash_drawers_all = useSelector(getHashDrawersAll)
  const hash_employees_all = useSelector(getHashEmployeesAll)
  const drawers = useSelector(getDrawersAll)
  const counts = useSelector(getCountsAll)
  const drawerPagination = useSelector(getDrawerPagination);
  const drawerPageSize = useSelector(getDrawerPageSize);
  const countPagination = useSelector(getCountPagination);
  const countPageSize = useSelector(getCountPageSize);
  const [onlyLatest, setOnlyLatest] = useState(true);
  const dispatch = useDispatch();
  const navTitle = "Cashcount"
  const HandleError = useHandleError();

  const drawersCard = {
    size: 12,
    title: "Drawers",
    data: <DrawersTable drawers={drawers} hash_admins_all={hash_admins_all} hash_locations_all={hash_locations_all} />,
  };

  const searchAndFilter = {
    settings: {
      minimal: {
        label: 'Show Only Latest Counts',
        onClick: () => { setOnlyLatest(!onlyLatest) },
        value: onlyLatest
      }
    }
  }

  const countCard = {
    size: 12,
    title: "Counts",
    subtitle: <><SearchAndMultiFilterSingleSelect {...searchAndFilter} /></>,
    data: <CountTable counts={counts}
      hash_admins_all={hash_admins_all}
      hash_locations_all={hash_locations_all}
      hash_drawers_all={hash_drawers_all}
      hash_employees_all={hash_employees_all}
    />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (admin_id) {
      dispatch(setLoading(true))
      axios.all([
        GetCashCountDrawerAll({ token: token, admin_id: admin_id, location_id: location_id, page: (drawerPagination + 1), size: drawerPageSize }),
        GetCashCountCountAll({ token: token, admin_id: admin_id, location_id: location_id, page: (countPagination + 1), size: countPageSize, onlyLatest: onlyLatest }),
        GetEmployees({ token: token, admin_id: admin_id }),
        GetUsersList({ token: token })
      ])
        .then(axios.spread((drawerData, countData, employeeData, serialsResponse) => {
          dispatch(setDrawersAll(drawerData.data))
          dispatch(setCountsAll(countData.data))
          dispatch(setEmployeesAll(employeeData.data))
          dispatch(setUsersAll(serialsResponse.data))
          dispatch(setLoading(false))
        }))
        .catch(error => {
          HandleError({ error: error })
          dispatch(setLoading(false))
        })
    }
    // eslint-disable-next-line
  }, [, admin_id, location_id, drawerPagination, drawerPageSize, countPagination, countPageSize, onlyLatest]);

  return (
    <>
      <BasicCard {...drawersCard} />
      <BasicCard {...countCard} />
    </>
  )
};
export default Cashcount;

