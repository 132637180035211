export const PrinterFormFields =
  [
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'admin_id', id: 'admin_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'select', name: 'Type', id: 'type', formdatafield: 'type', required: true },
    { type: 'text', name: 'Name', id: 'name', required: true },
    { type: 'text', name: 'IP', id: 'ip', required: true },
    { type: 'number', name: 'Port', id: 'port', required: true },
    { type: 'number', name: 'DPI', id: 'dpi', required: true },
    { type: 'select', name: 'Paper type', id: 'paper_type', formdatafield: 'paper_type', required: true },
    { type: 'number', name: 'Gap length', id: 'gap_length', required: true },
    { type: 'number', name: 'Gap offset', id: 'gap_offset', required: true }
  ]
