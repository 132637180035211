import { arrayMove } from "@dnd-kit/sortable"

export default function HandleOnDragEndMultiSelectFieldWithObjects(props) {
    const event = props.event
    const object = props.object || {}
    const selectfield = props.selectfield || props.event.id
  
    let newObject = JSON.parse(JSON.stringify(object))
    // let newObject = Object.assign({}, object)
    let excistingSelection = []
    let newSelection = []
    
    // clear newobject selectfield
    newObject[selectfield] = []
    // create array with excisting id's  
    if (object.hasOwnProperty(selectfield)) {
      // eslint-disable-next-line
      object[selectfield]?.map((item) => {
        excistingSelection.push(item)
      })
    }

    const { active, over } = event;
    if (!active || !over) return;
    const oldIndex = excistingSelection.findIndex((item) => item.id === active.id);
    const newIndex = excistingSelection.findIndex((item) => item.id === over.id);
    newSelection = arrayMove(excistingSelection, oldIndex, newIndex);
    
    newObject[selectfield] = newSelection
        

    return (newObject)
  }
