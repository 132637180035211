export default function HandleOnChangeSingleSelectFieldWithValue(props) {
  const event = props.event
  const object = props.object || {}
  const selectfield = props.selectfield || props.event.id
  const prefix = event?.prefix ?? props.prefix

  let newObject = JSON.parse(JSON.stringify(object))

  // clear newobject selectfield
  delete newObject[selectfield]

  // put new object in
  if (prefix) {
    newObject[prefix] = newObject[prefix] ?? undefined;
    newObject[prefix][selectfield] = event?.value ?? undefined;
  } else {
    newObject[selectfield] = event?.value ?? undefined;
  }
  
  return (newObject)
}