import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import NarrowcastingForm from "../../components/narrowcastingPlaylistForm";
import { NarrowcastingPlaylistFormFields } from "./narrowcastingPlaylistFormFields";
import HandleOnChange from "../../helpers/handleOnChange";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getPlaylist, setPlaylist, getViewsAll, getHashViewsAll, setViewsAll } from "../../redux/dataSlices/narrowcastingSlice";
import HandleOnChangeMultiSelectFieldWithObjects from "../../helpers/handleOnChangeMultiSelectFieldWithObjects";
import HandleOnDragEndMultiSelectFieldWithObjects from "../../helpers/handleOnDragEndMultiSelectFieldWithObjects";
import PostPlaylist from "../../actablueAPI/narrowcasting/PostPlaylist";
import GetAllViews from "../../actablueAPI/narrowcasting/GetAllViews";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { layoutChoices } from "../../selectfieldchoices/narrowcastingselect.mjs";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import useHandleError from "../../customhooks/useHandleError";
import MultiValueContainer from "../../components/narrowcastingPlaylistMultiContainer";

const NarrowcastingPlaylistEdit = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id)
  const location_id = useSelector(getSelectedLocation_id)
  const [localPlaylist, setLocalPlaylist] = useState(useSelector(getPlaylist))
  const views_all = useSelector(getViewsAll)
  const hash_views_all = useSelector(getHashViewsAll)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HandleError = useHandleError();
  const navTitle = "Playlist edit"

  if (localPlaylist.views) {
    let excistingPlaylist = {...localPlaylist}
    excistingPlaylist.views.forEach(view => {
      if (view.position === 0) {
        if (!excistingPlaylist.views1) { excistingPlaylist = {...excistingPlaylist, views1: []} }
        excistingPlaylist.views1.push(view)
      }
      if (view.position === 1) {
        if (!excistingPlaylist.views2) { excistingPlaylist = {...excistingPlaylist, views2: []} }
        excistingPlaylist.views2.push(view)
      }
      if (view.position === 2) {
        if (!excistingPlaylist.views3) { excistingPlaylist = {...excistingPlaylist, views3: []} }
        excistingPlaylist.views3.push(view)
      }
      if (view.position === 3) {
        if (!excistingPlaylist.views4) { excistingPlaylist = {...excistingPlaylist, views4: []} }
        excistingPlaylist.views4.push(view)
      }
    });
    delete excistingPlaylist.views
    setLocalPlaylist(excistingPlaylist)
  }

  const onChange = (event) => {
    let newPlaylist = HandleOnChange({ event: event, object: localPlaylist })
    setLocalPlaylist(newPlaylist)
  }

  const onChangeAllDay = (event) => {
    let newPlaylist = HandleOnChange({ event: event, object: localPlaylist })
    newPlaylist.start_time = event.target.checked ? '00:00' : null
    newPlaylist.end_time = event.target.checked ? '23:59' : null
    setLocalPlaylist(newPlaylist)
  }

  const onHandleOnChangeMultiSelectFieldWithObjects = (event, viewScreen) => {
    let newPlaylist = HandleOnChangeMultiSelectFieldWithObjects({ event: event, object: localPlaylist, selectfield: `views${viewScreen}`, hash: hash_views_all })
    newPlaylist[`views${viewScreen}`].forEach((view, index, array) => {
      if (view.position !== viewScreen-1) {
        view = {...view, position: viewScreen-1}
        array[index] = view
      }
    });
    setLocalPlaylist(newPlaylist)
  }

  const onDragEndMultiSelectFieldWithObjects = (event, viewScreen) => {
    let newPlaylist = HandleOnDragEndMultiSelectFieldWithObjects({ event: event, object: localPlaylist, selectfield: `views${viewScreen}`, hash: hash_views_all })
    setLocalPlaylist(newPlaylist)
  }

  const durationUpdate = ({duration, viewId, viewsList}) => {
    let newPlaylist = {...localPlaylist}
    if (viewId && viewsList) {
      newPlaylist[viewsList].forEach((view, index, array) => {
        if(view.id === viewId) {
          let newDuration = parseInt(duration)
          if (isNaN(newDuration)) {
            view = {...view, seconds: 0}
          } else {
            view = {...view, seconds: newDuration}
          }
          array[index] = view
        }
      })
      setLocalPlaylist(newPlaylist)
    }
  }

  const onLayoutChange = (event) => {
    let newPlaylist = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: localPlaylist, selectfield: 'layout' })
    setLocalPlaylist(newPlaylist)
  }

  const onCancel = () => {navigate(-1)}

  const onSubmit = () => {
    let newPlaylist = {...localPlaylist, views: []}
    if ((localPlaylist.views4) && (localPlaylist.layout === 'cross-split-screen')) {
      newPlaylist.views = newPlaylist.views.concat(localPlaylist.views4)
    }
    if ((localPlaylist.views3) && (localPlaylist.layout !== 'fullscreen' && localPlaylist.layout !== 'vertical-split-screen' && localPlaylist.layout !== 'horizontal-split-screen')) {
      newPlaylist.views = newPlaylist.views.concat(localPlaylist.views3)
    }
    if ((localPlaylist.views2) && (localPlaylist.layout !== 'fullscreen')) {
      newPlaylist.views = newPlaylist.views.concat(localPlaylist.views2)
    }
    if (localPlaylist.views1) {
      newPlaylist.views = newPlaylist.views.concat(localPlaylist.views1)
      delete newPlaylist.views1
      delete newPlaylist.views2
      delete newPlaylist.views3
      delete newPlaylist.views4
    }
    console.log(newPlaylist)
    dispatch(setPlaylist(newPlaylist))
    PostPlaylist({ token: token, admin_id: admin_id, playlist: newPlaylist })
      .then(() => {
        navigate("/narrowcastingplaylists");
      })
      .catch(error => {
        HandleError({ error: error })
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: NarrowcastingPlaylistFormFields(),
    field: {
      onChange: onChange
    },
    data: localPlaylist,
    all_day: {
      onChange: onChangeAllDay
    },
    views1: {
      label: 'views1',
      options: views_all,
      selectedViews: localPlaylist.views1,
      onChange: (event) => onHandleOnChangeMultiSelectFieldWithObjects(event,1),
      onDragEnd: (event) => onDragEndMultiSelectFieldWithObjects(event,1),
      clearable: Boolean(false),
      searchable: Boolean(true),
      MultiContainer: MultiValueContainer,
      durationUpdate: durationUpdate
    },
    views2: {
      label: 'views2',
      options: views_all,
      selectedViews: localPlaylist.views2,
      onChange: (event) => onHandleOnChangeMultiSelectFieldWithObjects(event,2),
      onDragEnd: (event) => onDragEndMultiSelectFieldWithObjects(event,2),
      clearable: Boolean(false),
      searchable: Boolean(true),
      MultiContainer: MultiValueContainer,
      durationUpdate: durationUpdate
    },
    views3: {
      label: 'views3',
      options: views_all,
      selectedViews: localPlaylist.views3,
      onChange: (event) => onHandleOnChangeMultiSelectFieldWithObjects(event,3),
      onDragEnd: (event) => onDragEndMultiSelectFieldWithObjects(event,3),
      clearable: Boolean(false),
      searchable: Boolean(true),
      MultiContainer: MultiValueContainer,
      durationUpdate: durationUpdate
    },
    views4: {
      label: 'views4',
      options: views_all,
      selectedViews: localPlaylist.views4,
      onChange: (event) => onHandleOnChangeMultiSelectFieldWithObjects(event,4),
      onDragEnd: (event) => onDragEndMultiSelectFieldWithObjects(event,4),
      clearable: Boolean(false),
      searchable: Boolean(true),
      MultiContainer: MultiValueContainer,
      durationUpdate: durationUpdate
    },
    layout: {
      label: 'layout',
      options: layoutChoices,
      onChange: onLayoutChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    }
  };

  const editCard = {
    size: 12,
    title: navTitle,
    data: <NarrowcastingForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    GetAllViews({ token: token, admin_id: admin_id, location_id: location_id })
      .then(response => {
        dispatch(setViewsAll(response))
      })
      .catch(error => {
        HandleError({ error: error })
      })
    // eslint-disable-next-line
  }, []);
  
  return (
    <>
      <BasicCard {...editCard} />
    </>
  )
  
};
export default NarrowcastingPlaylistEdit;

