import { Pie } from 'react-chartjs-2';
  export const usersStatusReportGraph = (report, hash_users_all) => {
    let graphCardData = <p>No data</p>

    if (Object.keys(report.users).length > 0) {
  
    let graphLabels = []
    let dataSetData = []
    let backGroundColor = []
    let borderColor = []

    for (const [userId, userData] of Object.entries(report.users).sort(([a, adata], [b, bdata]) => bdata.total.total - adata.total.total)) {
      let color = '#'+(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0');
      graphLabels.push(hash_users_all[userId] ? hash_users_all[userId].name : 'Unknown')
      dataSetData.push(userData.total.total)

      backGroundColor.push(color)
      borderColor.push(color)
    }
    
    const graphDataset = [
      {
        label: 'Value',
        data: dataSetData,
        borderColor: borderColor,
        backgroundColor: backGroundColor,
      },
    ]

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'right',
        }
      },
    };

    const data = {
      labels: graphLabels,
      datasets: graphDataset
    }

    graphCardData = <div className='pie-container'><Pie options={options} data={data} /></div>
  }

  return graphCardData
};