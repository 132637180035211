import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getRole, getToken } from "../../redux/dataSlices/tokenSlice";
import { setProduct, getProduct, setProductPagination, getProductModalShow, setProductModalTab, setProductModalShow, setProductPagesize, getProductPagesize, getSelectedProducts, setSelectedProducts, getProducts, setProducts } from "../../redux/dataSlices/productSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { getTemplateId, setTemplateId } from "../../redux/dataSlices/templateSlice";
import { pagesize } from "../../selectfieldchoices/pagesize.mjs";
import downloadShelflabels from "../../helpers/downloadShelflabels";
import { getSettingsPrinter, getSettingsSchapkaartjes } from "../../redux/dataSlices/settingsSlice";
import { useRef, useState } from "react";
import { DownloadShelflabelsFormFields } from "./productFormFields";
import DelProductById from "../../actablueAPI/products/DelProductByID";
import PutProduct from "../../actablueAPI/products/PutProduct";
import GetWarehouseStockProduct from "../../actablueAPI/warehouse/GetWarehouseStockProduct";
import GetCcvshopProductImportById from "../../actablueAPI/ccvshop/GetCcvshopProductImport";
import GetTemplateById from "../../actablueAPI/shelflabels/GetTemplateById";
import printShelflabelsBixolon from "../../helpers/printShelflabelsBixolon";
import useHandleError from "../../customhooks/useHandleError";
import HandleOnChange from "../../helpers/handleOnChange";
import PostProduct from "../../actablueAPI/products/PostProduct";

export default function ProductButtons() {
  const role = useSelector(getRole)
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const template_id = useSelector(getTemplateId);
  const modalShow = useSelector(getProductModalShow)
  const [downloadModalShow, setDownloadModalShow] = useState(false);
  const [bxlPrintModalShow, setBxlPrintModalShow] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const pageSize = useSelector(getProductPagesize)
  const scopes = useSelector(getScopes)
  const selectedProducts = useSelector(getSelectedProducts)
  const schapkaartjesSettings = useSelector(getSettingsSchapkaartjes)
  const products = useSelector(getProducts);
  const product = useSelector(getProduct)
  const printer = useSelector(getSettingsPrinter);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [controller, setController] = useState();
  const signal = useRef();
  const HandleError = useHandleError();

  const onProductSelect = (product) => {
    if (selectedProducts.hasOwnProperty(product.id)) {
      const newSelectedProducts = { ...selectedProducts };
      delete newSelectedProducts[product.id];
      dispatch(setSelectedProducts(newSelectedProducts));
    } else {
      const newSelectedProducts = { ...selectedProducts, [product.id]: product };
      dispatch(setSelectedProducts(newSelectedProducts));
    }
  };

  const onProductSelectAll = (products) => {
    const allSelected = products.every(
      (product) => selectedProducts.hasOwnProperty(product.id)
    );

    if (allSelected) {
      dispatch(setSelectedProducts({}));
    } else {
      const newSelectedProducts = { ...selectedProducts };

      products.forEach((product) => {
        newSelectedProducts[product.id] = product;
      });

      dispatch(setSelectedProducts(newSelectedProducts));
    }
  };

  function onViewClick(product) {
    dispatch(setProduct(product));
    dispatch(setProductModalShow(true));
  };

  const onViewHide = () => {
    dispatch(setProductModalTab('info'))
    dispatch(setProductModalShow(false));
  };

  const onclickEdit = (product) => {
    dispatch(setProduct(product))
    // dispatch(setProductId(event.target.id));
    navigate("/product/edit");
  };

  const onToggleActive = (product, refresh) => {
    let newProduct = JSON.parse(JSON.stringify(product))
    newProduct.active = !product.active
    PutProduct({ token: token, admin_id: admin_id, data: newProduct })
      // .then(response => {
      // })
      .catch(error => {
        HandleError({ error: error })
      })
      .finally(() => {
        refresh()
      })
  }

  const onclickPrint = (template, product) => {
    dispatch(setTemplateId(template.id));
    dispatch(setProduct(product));
    navigate("/product/print");
  };

  const onPaginationClick = (event) => {
    dispatch(setProductPagination(event.target.value))
  };

  const onProductPageSizeChange = (event) => {
    dispatch(setProductPagesize(event.value))
  };

  const onclickAdd = () => {
    navigate("/product/add");
  };

  const onDownloadClick = () => {
    dispatch(setTemplateId(''));
    setDownloadModalShow(true);
  }

  const onBxlPrintClick = () => {
    dispatch(setTemplateId(''));
    setBxlPrintModalShow(true);
  }

  const onChangeTemplate = (event) => {
    dispatch(setTemplateId(event?.value));
    GetTemplateById({ token: token, admin_id: admin_id, location_id: location_id, template_id: event?.value })
      .then(response => {
        setSelectedTemplate(response?.data)
      })
      .catch(err => {
        HandleError(err)
      })
  };

  const onDownloadShelflabels = () => {
    setDownloadModalShow(false);
    if (!selectedProducts) {
      return
    }
    downloadShelflabels(admin_id, location_id, selectedProducts, token, dispatch, navigate, schapkaartjesSettings, template_id)
  }

  const onPrintBxl = () => {
    setBxlPrintModalShow(false);
    if (!selectedProducts) {
      return
    }
    printShelflabelsBixolon(admin_id, location_id, selectedProducts, token, dispatch, navigate, schapkaartjesSettings, selectedTemplate, printer)
  }

  const onModalTabSelect = (value) => {
    dispatch(setProductModalTab(value))
    if (value === 'warehouse') {
      let tempProduct = Object.assign({}, product)
      dispatch(setLoading(true))
      GetWarehouseStockProduct({ token: token, admin_id: admin_id, location_id: location_id, product_id: product.id })
        .then(response => {
          if (Object.keys(response.data).length > 0) {
            tempProduct.warehouse = response.data
          }
          dispatch(setProduct(tempProduct))
          dispatch(setLoading(false))
        })
        .catch(function (error) {
          dispatch(setLoading(false))
        })
    }
  }

  const onclickImportCcvshop = (product) => {
    dispatch(setLoading(true))
    GetCcvshopProductImportById({ token: token, admin_id: admin_id, location_id: location_id, ccv_id: product.ccv.ccv_id })
      .then(response => {
        dispatch(setLoading(false))
      })
      .catch(error => {
        HandleError({ error: error })
        dispatch(setLoading(false))
      })
  }

  const onclickDelete = (event) => {
    dispatch(setLoading(true))
    DelProductById({ token: token, admin_id: admin_id, id: event.id })
      // .then(response => {
      // })
      .catch(error => {
        HandleError({ error: error })
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  };

  const stopAxios = async () => {
    if (controller) {
      controller.abort();
    }
  };

  const onChangeTable = async (event, object) => {
    stopAxios();
    let newProducts = JSON.parse(JSON.stringify(products));
    let newProduct = products?.content?.find(product => product?.id === object?.id);
    let newProductIndex = products?.content?.findIndex(product => product?.id === object?.id);
    newProduct = HandleOnChange({ event: event, object: newProduct });
    if (newProduct?.price_incl_vat < 0) return
    newProducts?.content?.splice(newProductIndex, 1, newProduct);
    dispatch(setProducts(newProducts))
    if (newProduct?.price_incl_vat === null || newProduct?.price_incl_vat === undefined) return

    let newController = new AbortController();
    setController(newController);
    signal.current = newController?.signal;

    newProduct = JSON.parse(JSON.stringify(newProduct));

    delete newProduct?.suppliers;

    PostProduct({ token: token, admin_id: admin_id, data: newProduct, signal: signal?.current })
      .then(() => { })
      .catch(error => {
        if (error?.code !== "ERR_CANCELED") HandleError({ error: error })
      })
  }

  let buttons = {
    modalview: {
      tag: "View",
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide
      }
    },
    select: {
      onClick: onProductSelect
    },
    selectAll: {
      onClick: onProductSelectAll
    },
    pagination: {
      maxbuttons: 5, //uneven number
      onClick: onPaginationClick
    },
    pagesize: {
      className: 'navbar-select',
      label: "page size selector",
      placeholder: "No selection",
      pagesize: pageSize,
      selected: { 'id': pageSize, 'name': pageSize },
      number_of_elements: 0,
      options: pagesize,
      onChange: onProductPageSizeChange
    },
    modalTabSelect: {
      onSelect: onModalTabSelect
    },
    onChange: onChangeTable
  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  if (scopes.products >= 1) {
    buttons.add = {
      tag: "Add",
      value: 'add',
      onClick: onclickAdd,
      className: 'add-button'
    }
  }
  if (scopes.products >= 2) {
    buttons.edit = {
      tag: "Edit",
      value: 'edit',
      onClick: onclickEdit
    }
    buttons.multiEdit = {
      tag: "Edit",
      value: 'multiEdit',
      onClick: onclickEdit
    }
    buttons.toggleActive = {
      tag: "ToggleActive",
      value: 'toggleActive',
      onClick: onToggleActive
    }
  }
  if (scopes.ccvshops >= 1) {
    buttons.importccvshop = {
      tag: 'Import from ccvshop',
      value: 'Import from ccvshop',
      onClick: onclickImportCcvshop
    }
  }
  if (scopes.schapkaartjes >= 0) {
    buttons.print = {
      tag: 'Print',
      value: 'print',
      onClick: onclickPrint
    }
    buttons.download = {
      tag: 'Download Shelflabels',
      value: 'download',
      onClick: onDownloadClick,
      modal: {
        data: template_id,
        title: "Select Label",
        tag: "Download",
        show: downloadModalShow,
        fields: DownloadShelflabelsFormFields(),
        toggleModal: setDownloadModalShow,
        download: onDownloadShelflabels,
        template_id: {
          label: 'template_id',
          placeholder: 'Select...',
          options: [],
          selected: [],
          onChange: onChangeTemplate,
          clearable: Boolean(false),
          searchable: Boolean(true)
        },
      }
    }
    buttons.bxlPrint = {
      tag: 'Print (Bixolon)',
      value: 'Print',
      onClick: onBxlPrintClick,
      modal: {
        data: template_id,
        title: "Select Label",
        tag: "Print",
        show: bxlPrintModalShow,
        fields: DownloadShelflabelsFormFields(),
        toggleModal: setBxlPrintModalShow,
        download: onPrintBxl,
        template_id: {
          label: 'template_id',
          placeholder: 'Select...',
          options: [],
          selected: [],
          onChange: onChangeTemplate,
          clearable: Boolean(false),
          searchable: Boolean(true)
        },
      }
    }
  }
  if (role === 'super' || role === 'admin') {
    buttons.delete = {
      tag: "Delete",
      value: 'delete',
      onClick: onclickDelete
    }
  }

  return buttons
}

