export const ImportFormFields = (importForm) => {
  const importFormFields = [
    { type: 'select', name: `Import Type`, id: 'importtype', formdatafield: 'importtype' },
    { type: 'file', name: `File`, id: 'file' },
    { type: 'select', name: 'Import profile', id: 'importprofile', formdatafield: 'importprofile' },
    { type: 'hidden', name: `Unique identifier`, id: 'Unique identifier', formdatafield: 'unique_id' },
    { type: 'hidden', name: `Import journalcategories`, id: 'journalcategories', formdatafield: 'journalcategories' },
    { type: 'hidden', name: `Option for multiple found sku/ean`, id: 'double_sku_ean', formdatafield: 'double_sku_ean' },
    { type: 'hidden', name: `Import only new products`, id: 'only_new', formdatafield: 'only_new' },
    { type: 'hidden', name: 'Supplier', id: 'supplier_id', formdatafield: 'supplier_id' },
    { type: 'hidden', name: 'Import stock', id: 'import_stock', formdatafield: 'import_stock' },
    { type: 'hidden', name: `Warehouse`, id: 'warehouse', formdatafield: 'warehouse' },
    { type: 'hidden', name: `Rack`, id: 'rack', formdatafield: 'rack' }

  ]

  if (importForm.importtype) {
    switch (true) {
      case importForm.importtype === 'stock':
        // importFormFields.splice(-7, 1, { type: 'select', name: `Unique identifier`, id: 'unique_id', formdatafield: 'unique_id' })
        importFormFields.splice(-3, 1, { type: 'select', name: 'Supplier', id: 'supplier', formdatafield: 'supplier' })
        importFormFields.splice(-2, 1, { type: 'select', name: `Warehouse`, id: 'warehouse', formdatafield: 'warehouse' })
        importFormFields.splice(-1, 1, { type: 'select', name: `Rack`, id: 'rack', formdatafield: 'rack' })
        break;
      case importForm.importtype.includes('products'):
        importFormFields.splice(-8, 1, { type: 'select', name: `Unique identifier`, id: 'unique_id', formdatafield: 'unique_id' })
        importFormFields.splice(-7, 1, { type: 'select', name: `Import journalcategories`, id: 'journalcategories', formdatafield: 'journalcategories' })
        importFormFields.splice(-6, 1, { type: 'select', name: `Option for multiple found sku/ean`, id: 'double_sku_ean', formdatafield: 'double_sku_ean' })
        importFormFields.splice(-5, 1, { type: 'select', name: `Import only new products`, id: 'only_new', formdatafield: 'only_new' })
        importFormFields.splice(-4, 1, { type: 'select', name: 'Supplier', id: 'supplier_id', formdatafield: 'supplier_id' })
        importFormFields.splice(-3, 1, { type: 'select', name: 'Import stock', id: 'import_stock', formdatafield: 'import_stock' })
        break
      default:
        break;
    }
  }

  return importFormFields
}

export const ProductImportFormFields = (headers, importForm) => {
  const productImportFormFields = [
    { type: 'select', name: `SKU Code`, id: 'sku_code', formdatafield: 'sku_code' },
    { type: 'select', name: `PLU Code`, id: 'plu_code', formdatafield: 'plu_code' },
    { type: 'select', name: `Name`, id: 'name', formdatafield: 'name' },
    { type: 'select', name: `Description`, id: 'description', formdatafield: 'description' },
    { type: 'select', name: `Thumbnail`, id: 'thumbnail', formdatafield: 'thumbnail' },
    { type: 'select', name: 'Active', id: 'active', formdatafield: 'active' },
    { type: 'select', name: `Discount`, id: 'discount', formdatafield: 'discount' },
    { type: 'select', name: `Price Excl. Vat`, id: 'price_excl_vat', formdatafield: 'price_excl_vat' },
    { type: 'select', name: `Price Incl. Vat`, id: 'price_incl_vat', formdatafield: 'price_incl_vat' },
    { type: 'select', name: `Ask SN`, id: 'ask_sn', formdatafield: 'ask_sn' },
    { type: 'select', name: `Emballage`, id: 'emballage', formdatafield: 'emballage' },
    { type: 'select', name: `VAT`, id: 'vat', formdatafield: 'vat' },
    { type: 'select', name: `EAN`, id: 'ean', formdatafield: 'ean' },
    { type: 'select', name: `Maingroup`, id: 'maingroup', formdatafield: 'maingroup' },
    { type: 'select', name: `Subgroup`, id: 'subgroup1', formdatafield: 'subgroup1' },
    { type: 'select', name: `Subgroup`, id: 'subgroup2', formdatafield: 'subgroup2' },
    { type: 'select', name: `Subgroup`, id: 'subgroup3', formdatafield: 'subgroup3' },
    { type: 'select', name: `Brand`, id: 'brand', formdatafield: 'brand' },
    { type: 'select', name: `Model`, id: 'model', formdatafield: 'model' },
    { type: 'select', name: `Packaging`, id: 'packaging', formdatafield: 'packaging' },
    { type: 'select', name: `Contents`, id: 'contents', formdatafield: 'contents' },
    { type: 'select', name: `Weight`, id: 'weigth', formdatafield: 'weigth' },
    { type: 'select', name: `Height`, id: 'heigth', formdatafield: 'heigth' },
    { type: 'select', name: `Length`, id: 'length', formdatafield: 'length' },
    { type: 'select', name: `Width`, id: 'width', formdatafield: 'width' },
    { type: 'select', name: `Batch number`, id: 'batch_number', formdatafield: 'batch_number' },
    { type: 'select', name: `Expires on`, id: 'expires_on', formdatafield: 'expires_on' },
    { type: 'select', name: `Minimum Stock`, id: 'minimum_stock', formdatafield: 'minimum_stock' },
    // { type: 'select', name: `Maximum Stock`, id: 'maximum_stock', formdatafield: 'maximum_stock' },
    // { type: 'select', name: `Dimension`, id: 'dimension', formdatafield: 'dimension' },
  ]

  if (importForm) {
    if (importForm.supplier_id) {
      productImportFormFields.push({ type: 'select', name: `Supplier product id`, id: 'supplier_artikel_code', formdatafield: 'supplier_artikel_code' })
      productImportFormFields.push({ type: 'select', name: `Purchaseprice Excl. Vat`, id: 'supplier_price', formdatafield: 'supplier_price' })
    }
  }

  if (headers) {
    const dynamic = []
    for (const header of Object.values(headers)) {
      dynamic.push({ type: 'select', name: header.name, id: header.id, formdatafield: 'dynamic', prefix: 'dynamic' })
    }
    productImportFormFields.push({ type: 'object', name: `Dynamic`, id: 'dynamic', fields: dynamic },)
  }

  return productImportFormFields
}

export const RelationImportFormFields = () => {
  const relationImportFormFields = [
    { type: 'select', name: 'user_id', id: 'user_id', formdatafield: 'user_id' },
    { type: 'select', name: 'name', id: 'name', formdatafield: 'name' },
    { type: 'select', name: 'active', id: 'active', formdatafield: 'active' },
  ]

  return relationImportFormFields
}

export const SaveFormFields = () => {
  const saveFormFields = [
    { type: 'text', name: 'name', id: 'name' },
    { type: 'checkbox', name: `new_profile`, id: 'new_profile', required: false },
  ]

  return saveFormFields
}

export const ProductImportActablueExportFormFields = (headers, importForm) => {
  const productImportFormFields = [
    { type: 'select', name: `Actablue id`, id: 'id', formdatafield: 'id' },
    { type: 'select', name: `Administration id`, id: 'admin_id', formdatafield: 'admin_id' },
    { type: 'select', name: `Location id`, id: 'location_id', formdatafield: 'location_id' },
    { type: 'select', name: `SKU Code`, id: 'sku_code', formdatafield: 'sku_code' },
    { type: 'select', name: `PLU Code`, id: 'plu_code', formdatafield: 'plu_code' },
    { type: 'select', name: `Type`, id: 'type', formdatafield: 'type' },
    { type: 'select', name: `Name`, id: 'name', formdatafield: 'name' },
    // { type: 'select', name: `Description`, id: 'description', formdatafield: 'description' },
    { type: 'select', name: `Thumbnail`, id: 'thumbnail', formdatafield: 'thumbnail' },
    { type: 'select', name: 'Active', id: 'active', formdatafield: 'active' },
    { type: 'select', name: 'Is weight', id: 'is_weighted', formdatafield: 'is_weighted' },
    { type: 'select', name: 'Input required', id: 'input_required', formdatafield: 'input_required' },
    { type: 'select', name: `Discount`, id: 'discount', formdatafield: 'discount' },
    { type: 'select', name: `Price Excl. Vat`, id: 'price_excl_vat', formdatafield: 'price_excl_vat' },
    { type: 'select', name: `Price Incl. Vat`, id: 'price_incl_vat', formdatafield: 'price_incl_vat' },
    // { type: 'select', name: `Ask SN`, id: 'ask_sn', formdatafield: 'ask_sn' },
    { type: 'select', name: `Emballage`, id: 'emballage', formdatafield: 'emballage' },
    { type: 'select', name: `VAT id`, id: 'vat_id', formdatafield: 'vat_id' },
    { type: 'select', name: `EAN id`, id: 'ean_id', formdatafield: 'ean_id' },
    { type: 'select', name: `Journal id`, id: 'journal_id', formdatafield: 'journal_id' },
    { type: 'select', name: `Productpage id`, id: 'productpage_id', formdatafield: 'productpage_id' },
    // { type: 'select', name: `Brand`, id: 'brand', formdatafield: 'brand' },
    // { type: 'select', name: `Model`, id: 'model', formdatafield: 'model' },
    // { type: 'select', name: `Packaging`, id: 'packaging', formdatafield: 'packaging' },
    // { type: 'select', name: `Contents`, id: 'contents', formdatafield: 'contents' },
    // { type: 'select', name: `Weight`, id: 'weigth', formdatafield: 'weigth' },
    // { type: 'select', name: `Height`, id: 'heigth', formdatafield: 'heigth' },
    // { type: 'select', name: `Length`, id: 'length', formdatafield: 'length' },
    // { type: 'select', name: `Width`, id: 'width', formdatafield: 'width' },
    // { type: 'select', name: `Batch number`, id: 'batch_number', formdatafield: 'batch_number' },
    // { type: 'select', name: `Expires on`, id: 'expires_on', formdatafield: 'expires_on' },
    // { type: 'select', name: `Dimension`, id: 'dimension', formdatafield: 'dimension' },
  ]

  if (importForm) {
    if (importForm.supplier) {
      productImportFormFields.push({ type: 'select', name: `Supplier product id`, id: 'supplier_artikel_code', formdatafield: 'supplier_artikel_code' })
      productImportFormFields.push({ type: 'select', name: `Purchaseprice Excl. Vat`, id: 'supplier_price', formdatafield: 'supplier_price' })
    }
  }

  if (headers) {
    const dynamic = []
    for (const header of Object.values(headers)) {
      dynamic.push({ type: 'select', name: header.name, id: header.id, formdatafield: 'dynamic', prefix: 'dynamic' })
    }
    productImportFormFields.push({ type: 'object', name: `Dynamic`, id: 'dynamic', fields: dynamic },)
  }

  return productImportFormFields
}

export const StockImportFormFields = (headers, importForm) => {
  const stockImportFormFields = []

  stockImportFormFields.push({ type: 'select', name: `Product id`, id: 'product_id', formdatafield: 'product_id' })
  stockImportFormFields.push({ type: 'select', name: `Stock`, id: 'quantity', formdatafield: 'quantity' })


  // if (importForm) {
  //   if (importForm.supplier) {
  //     stockImportFormFields.push({ type: 'select', name: `Supplier product id`, id: 'supplier_artikel_code', formdatafield: 'supplier_artikel_code' })
  //     stockImportFormFields.push({ type: 'select', name: `Purchaseprice Excl. Vat`, id: 'supplier_price', formdatafield: 'supplier_price' })  
  //     // stockImportFormFields.push({ type: 'select', name: `Warehouse`, id: 'warehouse', formdatafield: 'warehouse' })  
  //     // stockImportFormFields.push({ type: 'select', name: `Rack`, id: 'rack', formdatafield: 'rack' })  
  //     stockImportFormFields.push({ type: 'select', name: `Stock`, id: 'supplier_stock', formdatafield: 'supplier_stock' })  
  //   }
  // }

  return stockImportFormFields
}