import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { setTaskId, getTaskId, setTaskData, getImportModalShow, setImportModalShow, setImportProfiles, getImportProfiles, getHashImportProfiles, setImportProfile, getImportProfile, getMatchColumnsForm, setMatchColumnsForm, getImportSaveModalShow, setImportSaveModalShow } from "../../redux/dataSlices/importSlice";
import { read, utils } from 'xlsx'
import BasicCard from "../../components/card"
import ImportForm from "../../components/importForm";
import { ImportFormFields, SaveFormFields } from "./importFormFields.jsx";
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import ImportDataTable from "../../components/importDataTable";
import ImportMatchColumnsForm from "../../components/importMatchColumnsForm";
import { ImportDoubleSkuEan, ImportJournalCategories, ImportOnlyNew, ImportTypeChoices, ImportUniqueId } from "../../selectfieldchoices/importselect";
import ImportSaveModal from "../../components/importsaveModal";
import ImportProgressModal from "../../components/importprogressModal";
import GetImportProfilesAll from "../../actablueAPI/import/GetImportProfilesAll.jsx";
import { getHashRelationsAll, getRelationsAll, setRelationsAll } from "../../redux/dataSlices/relationSlice.js";
import GetRelationsByTypeAll from "../../actablueAPI/relations/GetRelationsByTypeAll.jsx";
import PostAnalyseTaskProgress from "../../actablueAPI/import/PostAnalyseTaskPostgress.jsx";
import PostImportProfile from "../../actablueAPI/import/PostImportProfile.jsx";
import PutImportProfile from "../../actablueAPI/import/PutImportProfile.jsx";
import GetWarehouseWarehouse from "../../actablueAPI/warehouse/GetWarehouseWarehouse.jsx";
import { useNavigate } from "react-router-dom";
import { getHashWarehousesAll, getWarehousesAll, setWarehousesAll } from "../../redux/dataSlices/warehouseSlice.js";
import useHandleError from "../../customhooks/useHandleError.jsx";

const Importing = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const task_id = useSelector(getTaskId)
  const modal_show = useSelector(getImportModalShow)
  const save_modal_show = useSelector(getImportSaveModalShow)
  const import_profile = useSelector(getImportProfile)
  const import_profiles = useSelector(getImportProfiles)
  const hash_import_profiles = useSelector(getHashImportProfiles)
  const match_columns_form = useSelector(getMatchColumnsForm)
  const relationsAll = useSelector(getRelationsAll)
  const hashRelationsAll = useSelector(getHashRelationsAll)
  const warehousesAll = useSelector(getWarehousesAll)
  const hashWarehousesAll = useSelector(getHashWarehousesAll)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const defaultImportForm = { unique_id: 'SKU', double_sku_ean: 'NOTHING', only_new: 'NO', journalcategories: 'NO', import_stock: 'NO' }
  const [importForm, setImportForm] = useState(defaultImportForm)
  const [file, setFile] = useState(null)
  const [dataFromFile, setDataFromFile] = useState({})
  const [headers, setHeaders] = useState([])
  const HandleError = useHandleError();

  const onViewHide = () => {
    dispatch(setImportModalShow(false));;
    window.location.reload();
  };

  const onChange = (event) => {
    setFile(event.target?.files?.[0]);
  }

  const onImportTypeChange = (event) => {
    let newImportForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: importForm, selectfield: 'importtype' });
    setImportForm(newImportForm);
  };

  const onImportProfileChange = (event) => {
    let newImportForm = JSON.parse(JSON.stringify(importForm))
    newImportForm.importprofile = event?.value
    const options = hash_import_profiles?.[event?.value]?.options
    newImportForm.unique_id = options?.unique_id ?? defaultImportForm?.unique_id ?? null;
    newImportForm.double_sku_ean = options?.double_sku_ean ?? defaultImportForm?.double_sku_ean ?? null;
    newImportForm.only_new = options?.only_new ?? defaultImportForm?.only_new ?? null;
    newImportForm.journalcategories = options?.journalcategories ?? defaultImportForm?.journalcategories ?? null;
    newImportForm.supplier_id = options?.supplier_id ?? defaultImportForm?.supplier_id ?? null;
    newImportForm.import_stock = options?.import_stock ?? defaultImportForm?.import_stock ?? null;
    setImportForm(newImportForm)
  };

  const onChangeImportSetting = (event, selectFieldId) => {
    let newImportForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: importForm, selectfield: selectFieldId })
    setImportForm(newImportForm)
  };

  const onUpload = () => {
    if (!file) {
      HandleError({ error: 'Please upload a file' })
      return;
    }
    const readerProducts = new FileReader();
    readerProducts.readAsArrayBuffer(file);
    readerProducts.onload = (event) => {
      try {
        const arrayBuffer = event.target.result;

        // Convert ArrayBuffer to binary string
        const binaryString = Array.from(new Uint8Array(arrayBuffer))
          .map(byte => String.fromCharCode(byte))
          .join("");

        const workbook = read(binaryString, { type: "binary" });
        const sheetName = workbook.SheetNames?.[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = utils.sheet_to_json(sheet, { defval: null });

        setDataFromFile({ data: parsedData });

        if (parsedData.length > 0) {
          const newHeaders = Object.keys(parsedData?.[0]).map((key) => ({
            id: key,
            name: key,
          }));
          setHeaders(newHeaders);
        }
      } catch (error) {
        console.error("Error processing file:", error);
      }
    };

    // profile
    dispatch(setImportProfile(hash_import_profiles[importForm.importprofile]))

    // proces import_profile
    if (hash_import_profiles[importForm.importprofile]) {
      let mappingProfile = JSON.parse(hash_import_profiles[importForm.importprofile]?.mapping_profile?.option)
      let newMatchColumnsForm = Object.assign({}, match_columns_form)
      for (const [key, value] of Object.entries(mappingProfile)) {
        newMatchColumnsForm[key] = value
      }
      dispatch(setMatchColumnsForm(newMatchColumnsForm))
    }
  };

  const onChangeSaveFormName = (event) => {
    let newImportProfile = HandleOnChange({ event: event, object: import_profile })
    dispatch(setImportProfile(newImportProfile))
  }

  const importSaveModalHide = () => {
    dispatch(setImportSaveModalShow(false));

    if (import_profile.id && !import_profile.new_profile) {
      let newProfile = JSON.parse(JSON.stringify(import_profile))
      let newMappingProfile = JSON.parse(JSON.stringify(newProfile?.mapping_profile))
      newMappingProfile.option = JSON.stringify(match_columns_form)
      newProfile.mapping_profile = newMappingProfile
      newProfile.options = {
        id: import_profile?.options?.id,
        unique_id: importForm?.unique_id ?? null,
        journalcategories: importForm?.journalcategories ?? null,
        double_sku_ean: importForm?.double_sku_ean ?? null,
        only_new: importForm?.only_new ?? null,
        supplier_id: importForm?.supplier_id ?? null,
        import_stock: importForm?.import_stock ?? null
      }

      PutImportProfile({ token: token, data: newProfile })
        .catch(error => {
          HandleError({ error: error })
        })

    }

    if (!import_profile.id || import_profile.new_profile) {
      let newProfile = JSON.parse(JSON.stringify(import_profile))
      newProfile.administration_id = admin_id
      newProfile.location_id = location_id
      newProfile.mapping_profile = { option: JSON.stringify(match_columns_form) }
      newProfile.options = {
        unique_id: importForm?.unique_id ?? null,
        journalcategories: importForm?.journalcategories ?? null,
        double_sku_ean: importForm?.double_sku_ean ?? null,
        only_new: importForm?.only_new ?? null,
        supplier_id: importForm?.supplier_id ?? null,
        import_stock: importForm?.import_stock ?? null
      }

      PostImportProfile({ token: token, data: newProfile })
        .catch(error => {
          HandleError({ error: error })
        })
    }

  };

  const onCancel = () => { navigate(-1) }

  const importSaveModal = {
    modal: {
      show: save_modal_show,
      onHide: importSaveModalHide
    }
  }

  const saveFormData = {
    fields: SaveFormFields(),
    field: {
      onChange: onChangeSaveFormName
    },
    data: import_profile
  }

  const importProgressModal = {
    modal: {
      show: modal_show,
      onHide: onViewHide
    }
  }

  const importingFormData = {
    submit: {
      title: 'Upload',
      type: 'submit',
      onClick: onUpload
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: ImportFormFields(importForm),
    field: {
      onChange: onChange
    },
    data: importForm,
    hashRelationsAll: hashRelationsAll,
    importtype: {
      name: 'importtype',
      options: ImportTypeChoices(),
      // selected: {},
      onChange: onImportTypeChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    importprofile: {
      name: 'importprofile',
      options: import_profiles,
      // selected: {},
      onChange: onImportProfileChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    unique_id: {
      name: 'unique_id',
      options: ImportUniqueId(),
      // selected:  { value: 'SKU', label: 'SKU' },
      onChange: onChangeImportSetting,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    double_sku_ean: {
      name: 'double_sku_ean',
      options: ImportDoubleSkuEan(),
      // selected:  { value: 'SKU', label: 'SKU' },
      onChange: onChangeImportSetting,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    only_new: {
      name: 'only_new',
      options: ImportOnlyNew(),
      // selected:  { value: 'SKU', label: 'SKU' },
      onChange: onChangeImportSetting,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    journalcategories: {
      name: 'journalcategories',
      options: ImportJournalCategories(),
      // selected:  { value: 'SKU', label: 'SKU' },
      onChange: onChangeImportSetting,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    supplier_id: {
      name: 'supplier_id',
      options: relationsAll,
      // selected: {},
      onChange: onChangeImportSetting,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    warehouse: {
      name: 'warehouse',
      options: warehousesAll,
      // selected: {},
      onChange: onChangeImportSetting,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    rack: {
      name: 'rack',
      options: hashWarehousesAll[importForm.warehouse]?.racks,
      // selected: {},
      onChange: onChangeImportSetting,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    import_stock: {
      name: 'import_stock',
      options: ImportOnlyNew(),
      // selected:  { value: 'SKU', label: 'SKU' },
      onChange: onChangeImportSetting,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
  }

  const card = {
    size: 12,
    title: 'Importing',
    // data: location_id ? <ImportForm {...importingFormData} /> : 'Choose location',
    data: <ImportForm {...importingFormData} />,
  };

  let matchCard = {
    size: 12,
    title: "Data",
    data: <ImportMatchColumnsForm importForm={importForm} headers={headers} data={dataFromFile} matchColumnsForm={match_columns_form} />,
  }

  const dataCard = {
    size: 12,
    title: 'Import example (10 rows)',
    data: <ImportDataTable {...dataFromFile} />,
  }

  useEffect(() => {
    dispatch(setNavBarTitle('Importing'))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setImportForm(defaultImportForm)
    setFile(null)
    setDataFromFile({})
    setHeaders([])
    dispatch(setMatchColumnsForm())
    GetImportProfilesAll({ token: token, admin_id: admin_id, location_id: location_id })
      .then(importProfilesAll => {
        dispatch(setImportProfiles(importProfilesAll))
      })
      .catch(error => {
        HandleError({ error: error })
      })
    // eslint-disable-next-line
  }, [admin_id, location_id]);

  useEffect(() => {
    switch (importForm.importtype) {
      case 'products':
      case 'products+locations':
        GetRelationsByTypeAll({ token: token, admin_id: admin_id, type: 'SUPPLIER' })
          .then(relationsAll => {
            dispatch(setRelationsAll(relationsAll))
          })
          .catch(error => {
            HandleError({ error: error })
          })
        break;
      // case 'relations':
      //   break
      // case 'actablueExportFile':
      //   break
      case 'stock':
        axios.all([
          GetRelationsByTypeAll({ token: token, admin_id: admin_id, type: 'SUPPLIER' }),
          GetWarehouseWarehouse({ token: token, admin_id: admin_id, location_id: location_id })
        ])
          .then(axios.spread((relationsAll, warehouseResponse) => {
            dispatch(setRelationsAll(relationsAll))
            dispatch(setWarehousesAll(warehouseResponse.data.content))
          }))
          .catch(error => {
            HandleError({ error: error })
          })
        break
      default:
        break;
    }
    // eslint-disable-next-line
  }, [importForm.importtype]);


  useEffect(() => {
    let intervalId = {}
    function getProgres() {
      if (task_id) {
        PostAnalyseTaskProgress({ data: { task_id: task_id } })
          .then(response => {
            if (response.data.result.state === 'PENDING') {
              dispatch(setTaskData(response.data.result))
            }

            if (response.data.result.state === 'PROGRESS') {
              dispatch(setTaskData(response.data.result))
            }

            if (response.data.result.state === 'SUCCESS') {
              dispatch(setTaskData(response.data.result))
              clearInterval(intervalId)
              dispatch(setTaskId(undefined))
              dispatch(setImportModalShow(false))
              window.location.reload()
            }
          })
          .catch(error => {
            clearInterval(intervalId)
            dispatch(setTaskId(undefined))
            dispatch(setImportModalShow(false))
            window.location.reload()
            HandleError({ error: error })
          })
      }
    }

    if (task_id) {
      dispatch(setImportModalShow(true))
      intervalId = setInterval(() => {
        getProgres()
      }, 1000);
    }

    if (!task_id) {
      dispatch(setTaskData(undefined))
      clearInterval(intervalId)
    }
    // eslint-disable-next-line
  }, [task_id]);

  return (
    <>
      <ImportProgressModal {...importProgressModal} />
      <ImportSaveModal modal={importSaveModal.modal} formData={saveFormData} />
      <BasicCard {...card} />
      {Object.keys(dataFromFile).length > 0 && <BasicCard {...matchCard} />}
      {Object.keys(dataFromFile).length > 0 && <BasicCard {...dataCard} />}
    </>
  )
};
export default Importing;