import { Button, Col, Form, Table } from 'react-bootstrap'
import AdviceButtons from '../portal/stock/advicebuttons';
import WarehousePurchaseAdviceModal from './warehousePurchaseAdviceModal';
import { useTranslation } from 'react-i18next';
import PaginationAndSizeComponent from './paginationAndSize';

export default function WarehouseAdviceTable(adviceList) {
  const list = adviceList.content
  const buttons = AdviceButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const { t: translate } = useTranslation();

  let pagination = buttons.pagination
  let pagesize = buttons.pagesize

  let pages = adviceList?.total_pages
  let page = adviceList?.number + 1

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  let adviceRows = []
  if (list) {
    for (let product of Object.values(list)) {
      adviceRows.push(
        <tr className='category-row' key={product.id}>
          <td>{product.name ?? "N/A"}</td>
          <td>
            <Col sm="10">
              <Form.Control
                type={'number'}
                name={'minimum_stock'}
                id={'minimum_stock'}
                value={product?.minimum_stock === null ? "" : product?.minimum_stock}
                onChange={(event) => { buttons?.onChange(event, product) }}
                onWheel={numberInputOnWheelPreventChange}
              />
            </Col>
          </td>
          <td>{product.total_quantity ?? "N/A"}</td>
          <td>{product.purchase_advice ?? "N/A"}</td>
          <td>{product.packaging ?? "1"}</td>
          <td>
            <Button size="sm" type='button' onClick={() => { modalview.onClick(product) }}>{translate(modalview.tag)}</Button>
            {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(product) }}>{translate(edit.tag)}</Button>}
          </td>
        </tr>
      )
    }
  }

  return (
    <>
      {<PaginationAndSizeComponent pagination={pagination} pagesize={pagesize} pages={pages} page={page} />}
      <Table width='100%'>
        <thead key='header'>
          <tr>
            <th>{translate('Name')}</th>
            <th>{translate('Minimum stock')}</th>
            {/* <th>{translate('Maximum stock')}</th> */}
            <th>{translate('Stock')}</th>
            <th>{translate('Purchase advice')}</th>
            <th>{translate('Order unit')}</th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {adviceRows}
        </tbody>
        <WarehousePurchaseAdviceModal modal={modalview.modal} />
      </Table>
    </>
  );
}