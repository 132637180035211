import { Button, Col, Form, Table } from 'react-bootstrap'
import TableButton from './tableButton';
import StockButtons from '../portal/stock/stockbuttons';
import StockModal from './stockModal';
import { useTranslation } from 'react-i18next';
import PaginationAndSizeComponent from './paginationAndSize';
export default function StockTable(stock) {
  const buttons = StockButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const exportStock = buttons.hasOwnProperty('export') ? Object.assign({}, buttons.export) : false
  const resetToZero = buttons.hasOwnProperty('resettozero') ? Object.assign({}, buttons.resettozero) : false
  const refresh = buttons.hasOwnProperty('refresh') ? Object.assign({}, buttons.refresh) : false
  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const { t: translate } = useTranslation();

  let pagination = buttons.pagination
  let pagesize = buttons.pagesize

  let stockRows = []
  const pages = stock.total_pages
  const page = (stock.number + 1)

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  if (stock?.content?.length > 0) {
    for (let stockProduct of Object.values(stock.content)) {
      let stockDetail = []
      let quantity = 0
      if (stockProduct.product_locations) {
        const productLocations = stockProduct.product_locations
        for (const stockLocation of productLocations) {
          quantity += stockLocation.quantity
          stockDetail.push(
            <tr>
              <td>{stockLocation.warehouse_name ?? "N/A"}</td>
              <td>{stockLocation.rack_name ?? "N/A"}</td>
              <td>{stockLocation.quantity ?? "N/A"}</td>
            </tr>
          )
        }
      }

      stockRows.push(
        <tr className='product-row' key={stockProduct.id}>
          <td>{stockProduct.sku_code ?? "N/A"}</td>
          <td>{stockProduct.name ?? "N/A"}</td>
          <td>
            <Col sm="10">
              <Form.Control
                type={'number'}
                name={'minimum_stock'}
                id={'minimum_stock'}
                value={stockProduct?.minimum_stock === null ? "" : stockProduct?.minimum_stock}
                onChange={(event) => { buttons?.onChange(event, stockProduct) }}
                onWheel={numberInputOnWheelPreventChange}
              />
            </Col>
          </td>
          {/* {
            fields.map(field => (
              CreateFieldTable(field, stockProduct, buttons)
            ))
          } */}
          <td>{quantity ?? "N/A"}</td>
          <td>
            {stockProduct.product_locations.length > 0
              &&
              <Table width={100}>
                <thead>
                  <tr>
                    <th>{translate('Warehouse')}</th>
                    <th>{translate('Rack')}</th>
                    <th>{translate('Quantity')}</th>
                  </tr>
                </thead>
                <tbody>
                  {stockDetail}
                </tbody>
              </Table>
            }
          </td>
          <td>
            <Button size="sm" type='button' onClick={() => { modalview.onClick(stockProduct) }} id={stockProduct.id}>{translate(modalview.tag)}</Button>
            {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(stockProduct) }}>{translate(edit.tag)}</Button>}
          </td>
        </tr>
      )
    }
  }
  return (
    <>
      {refresh && <TableButton {...refresh} />}{resetToZero && <TableButton {...resetToZero} />}{exportStock && <TableButton {...exportStock} />}
      {<PaginationAndSizeComponent pagination={pagination} pagesize={pagesize} pages={pages} page={page} />}
      <Table width='100%'>
        <thead>
          <tr key='header'>
            <th>{translate('SKU code')}</th>
            <th>{translate('Name')}</th>
            <th>{translate('Minimum Stock')}</th>
            <th>{translate('Calculated Total')}</th>
            <th>{translate('Stock Detail')}</th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {stockRows}
        </tbody>
        <StockModal modal={modalview.modal} />
      </Table>
    </>
  );
}