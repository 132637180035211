export const usersStatusReportHighlights = (report, hash_users_all) => {
  let highlights = []
  let totalRevenue = 0

  for (const [userId, userData] of Object.entries(report.users).sort(([a, adata], [b, bdata]) => bdata.total.total - adata.total.total)) {
    totalRevenue = totalRevenue + userData.total.total
    let newLine = {}
    newLine[hash_users_all[userId] ? hash_users_all[userId].name : 'Unknown'] = {amount:Number(userData.total.total).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}
    highlights.push(newLine)
  }

  // add total line
  let newLine = {}
  newLine['Total'] = {amount:Number(totalRevenue).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}
  highlights.push(newLine)
  return highlights
};