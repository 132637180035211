export async function CalculateReportingTotals(reportingData, start_date, end_date) {
  let reportingTotalsPerOperator = {}

  for (const date in reportingData?.days) {
    const users = reportingData?.days[date];
    for (const user in users) {
      if (!reportingTotalsPerOperator[user]) {
        reportingTotalsPerOperator[user] = {
          start_date: start_date,
          end_date: end_date,
          cash: 0,
          netto_cash: 0,
          eft: 0,
          exchange: 0,
          off_account: 0,
          on_account: 0,
          webshop: 0,
          total: 0,
        };
      }
      for (const key in users[user]) {
        reportingTotalsPerOperator[user][key] += users[user][key];
      }
      reportingTotalsPerOperator[user]['netto_cash'] = (reportingTotalsPerOperator?.[user]?.['cash'] - reportingTotalsPerOperator?.[user]?.['exchange'])
    }
  }
  return reportingTotalsPerOperator
}