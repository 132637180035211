import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Tab, Nav, Table, Col, Row } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import CreateFieldTemplate from '../helpers/createFieldTemplate';
import { useSelector } from 'react-redux';
import { getHashDevicesUidAll } from '../redux/dataSlices/deviceSlice';
import { getHashEmployeesUidAll } from '../redux/dataSlices/employeeSlice';
import { getHashDrawersAll } from '../redux/dataSlices/cashcountSlice';

export default function CountForm(props) {
  const { t: translate } = useTranslation();
  const hashDevicesAll = useSelector(getHashDevicesUidAll);
  const hashEmployeesAll = useSelector(getHashEmployeesUidAll);
  const hashDrawersAll = useSelector(getHashDrawersAll);
  const allowedKeys = ['netto_cash', 'eft', 'on_account', 'total'];
  let previous_day_amount = 0;
  let reporting_total = 0;

  if (props?.selectedDrawer?.previous_count) previous_day_amount = props?.selectedDrawer?.previous_count?.next_day_amount
  if (props?.reportingData?.total) reporting_total = (props?.reportingData?.cash - props?.reportingData?.exchange)

  if (props?.data?.counted && props?.data?.counted?.total !== "0.00") {
    props.fields2.find(field => field.id === 'counted_total').disabled = true;
  }

  if (props?.selectedDrawer) {
    switch (props?.selectedDrawer?.count_as) {
      case "Employee":
        props.fields.find(field => field.id === 'employee_id').type = 'select';
        props.fields.find(field => field.id === 'device_id').type = 'hidden';
        if (props?.edit) break;
        if (hashDrawersAll?.[props?.selectedDrawer?.id]?.employee_id) props.employee_id.disabled = true
        if (!hashDrawersAll?.[props?.selectedDrawer?.id]?.employee_id) props.employee_id.disabled = false
        break;
      case "Device":
        props.fields.find(field => field.id === 'device_id').type = 'select';
        props.fields.find(field => field.id === 'employee_id').type = 'hidden';
        if (props?.edit) break;
        if (hashDrawersAll?.[props?.selectedDrawer?.id]?.device_id) props.device_id.disabled = true
        if (!hashDrawersAll?.[props?.selectedDrawer?.id]?.device_id) props.device_id.disabled = false
        break;
      default:
        props.fields.find(field => field.id === 'employee_id').type = 'hidden';
        props.fields.find(field => field.id === 'device_id').type = 'hidden';
        props.device_id.disabled = true
        props.employee_id.disabled = true
        break;
    }
  }

  if (props.hasOwnProperty('drawer_id') && props.drawers && !props.drawers.items) {
    if (props?.data?.drawer_id) props.drawer_id.selected = { 'value': props?.data?.drawer_id, 'label': hashDrawersAll[props?.data?.drawer_id]?.name }
    if (!props?.data?.drawer_id) props.drawer_id.selected = null;
  }

  if (props?.data?.hasOwnProperty('device_id')) {
    if (props?.data?.device_id) props.device_id.selected = { 'value': props?.data?.device_id, 'label': hashDevicesAll[props?.data?.device_id]?.name }
    if (!props?.data?.device_id) props.device_id.selected = null;
  }

  if (props?.data?.hasOwnProperty('employee_id')) {
    if (props?.data?.employee_id) props.employee_id.selected = { 'value': props?.data?.employee_id, 'label': hashEmployeesAll[props?.data?.employee_id]?.name }
    if (!props?.data?.employee_id) props.employee_id.selected = null;
  }

  let fields = [];
  let basicFields = [];
  let basicFields2 = [];
  let formTabs = [];
  let formTabContent = [];
  if (props.allowedCurrency.hasOwnProperty('allowed_currency')) {
    const sortedCurrency = JSON.parse(JSON.stringify(props?.allowedCurrency?.allowed_currency))?.sort((a, b) => parseFloat(a) - parseFloat(b))
    sortedCurrency.forEach(currency => {
      fields?.push(
        <tr key={currency}>
          <td>
            <Form.Label>{Number(currency).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Form.Label>
          </td>
          <td>
            <Form.Control type={'number'}
              id={`${currency}-number`}
              role={'total'}
              name={currency}
              value={props?.data?.counted?.currency?.[currency]?.count ?? ""}
              placeholder={translate("Enter an amount...")}
              onChange={props?.field?.onChange}
              required={true}
              disabled={props?.data?.counted_total}
            />
          </td>
          <td>
            <Form.Control type={'number'}
              id={`${currency}-total`}
              role={'value'}
              name={currency}
              value={props?.data?.counted?.currency?.[currency]?.total ?? ""}
              placeholder={translate("Enter an amount...")}
              onChange={props?.field?.onChange}
              required={true}
              disabled={props?.data?.counted_total}
            />
          </td>
        </tr>
      )
    });
  }

  props?.fields?.forEach(field => {
    if (field.type === 'object') {
      formTabs.push(
        <Nav.Item key={field.id}>
          <Nav.Link eventKey={field.id}>
            {translate(field.name)}
          </Nav.Link>
        </Nav.Item>
      );

      let objectFields = []
      field.fields.forEach(embeddedfield => {
        objectFields.push(CreateFieldTemplate(embeddedfield, props, field.id))
      });
      formTabContent.push(
        <Tab.Pane eventKey={field.id} key={field.id}>
          <div className='tab-container'>
            {objectFields}
          </div>
        </Tab.Pane>
      );
    }
    else {
      basicFields.push(CreateFieldTemplate(field, props))
    }
  });

  props?.fields2?.forEach(field => {
    if (field.type === 'object') {
      formTabs.push(
        <Nav.Item key={field.id}>
          <Nav.Link eventKey={field.id}>
            {translate(field.name)}
          </Nav.Link>
        </Nav.Item>
      );

      let objectFields = []
      field.fields.forEach(embeddedfield => {
        objectFields.push(CreateFieldTemplate(embeddedfield, props, field.id))
      });
      formTabContent.push(
        <Tab.Pane eventKey={field.id} key={field.id}>
          <div className='tab-container'>
            {objectFields}
          </div>
        </Tab.Pane>
      );
    }
    else {
      basicFields2.push(CreateFieldTemplate(field, props))
    }
  });
  return (
    <>
      <Form onSubmit={props.submit.onClick}>
        <Tab.Container defaultActiveKey="main">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="main">
                {translate('Main')}
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="main">
              <div className='tab-container'>
                <Row>
                  <Col md={8}>
                    <Row key={`fields-row`} className='h-100 align-items-center'>
                      {basicFields}
                    </Row>
                  </Col>
                  <Col md={'auto'}>
                    <div className="vl"></div>
                  </Col>
                  <Col md={3}>
                    <h5>{translate('Reporting Data')}</h5>
                    {(props.selectedDrawer && props?.reportingData) && <h5>{props?.reportingData?.start_date} - {props?.reportingData?.end_date}</h5>}
                    {props.selectedDrawer ?
                      !props.reportingLoading ?
                        props.selectedDrawer.previous_count ?
                          (props.data.device_id || props.data.employee_id) ?
                            <>
                              {!props?.reportingData && translate('No reporting data available.')}
                              <Table width='100%'>
                                <tbody>
                                  {props?.reportingData && Object.entries(props?.reportingData).map(([key, value]) => {
                                    if (allowedKeys.includes(key)) {
                                      return (
                                        <tr key={key}>
                                          <td width={'40%'}><b>{key.charAt(0).toUpperCase() + key.slice(1).replace("_", " ")}:</b></td>
                                          <td>{Number(value).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                                        </tr>
                                      )
                                    }
                                    return <></>;
                                  })}
                                  <tr key={'previous_amount'}>
                                    <td width={'40%'}><b>{translate('Previous drawer amount')}:</b></td>
                                    <td>{Number(props?.selectedDrawer?.previous_count?.next_day_amount || 0).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                                  </tr>
                                </tbody>
                              </Table>
                            </>
                            :
                            translate('Please select an employee/device.')
                          :
                          translate('First count for this drawer. No reporting data available.')
                        :
                        translate('Loading...')
                      :
                      translate('Please select a drawer')
                    }
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <Table hover width='100%'>
                      <thead>
                        <tr>
                          <th width="20%"></th>
                          <th width="40%">
                            {translate('Total')}
                          </th>
                          <th width="40%">
                            {translate('Value')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {fields}
                      </tbody>
                    </Table>
                  </Col>
                  <Col md={'auto'}>
                    <div className="vl"></div>
                  </Col>
                  <Col md={3} className='d-flex align-items-end'>
                    <Row key={`fields-row`}>
                      {basicFields2}
                    </Row>
                  </Col>
                </Row>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
        <h4 className='count-form-total'>
          {translate('COUNTED')}: {Number(props?.data?.counted?.total ?? 0).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}
        </h4>
        <br />
        <br />
        <h4 className='count-form-total'>
          {translate('EXPECTED')}: {Number((reporting_total ?? 0 + previous_day_amount ?? 0) ?? 0).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}
        </h4>
        <br />
        <br />
        <h4 className={`count-form-total-${Number(props?.data?.discrepancy || 0) !== 0 ? "incorrect" : "correct"}`}>
          {translate('DISCREPANCY')}: {Number(props?.data?.discrepancy ?? 0).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}
        </h4>
        <br />
        <br />
        <h4 className={`count-form-total-${Number(props?.data?.eft_discrepancy || 0) !== 0 ? "incorrect" : "correct"}`}>
          {translate('EFT DISCREPANCY')}: {Number(props?.data?.eft_discrepancy ?? 0).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}
        </h4>
        <br />
        <br />
        <h4 className={`count-form-total-${Number(props?.data?.on_account_discrepancy || 0) !== 0 ? "incorrect" : "correct"}`}>
          {translate('ON ACCOUNT DISCREPANCY')}: {Number(props?.data?.on_account_discrepancy ?? 0).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}
        </h4>
        <br />
        <br />
        <Button variant="primary" type={props.cancel.type} onClick={props.cancel.onClick}>{translate(props.cancel.title)}</Button>
        <Button variant="primary" type={props.submit.type} onClick={props.submit.onClick}>{translate(props.submit.title)}</Button>
        <p className='form-required-text-hint'>{translate("Fields with * are required")}</p>
      </Form>
    </>
  );
}
