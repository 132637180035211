export const CountFormFields = () => {

  const countFormFields = [
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'admin_id', id: 'admin_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'select', name: 'Drawer', id: 'drawer_id', formdatafield: 'drawer_id', required: true, md: 6 },
    { type: 'hidden', name: 'Device', id: 'device_id', formdatafield: 'device_id', required: true, md: 6 },
    { type: 'hidden', name: 'Employee', id: 'employee_id', formdatafield: 'employee_id', required: true, md: 6 }
  ]

  return countFormFields
}

export const CountFormFields2 = () => {

  const countFormFields = [
    { type: 'number', name: `Next day amount`, id: 'next_day_amount', required: true, step: '.01', md: 12 },
    { type: 'number', name: `Skimming`, id: 'skimming', required: true, step: '.01', md: 12 },
    { type: 'number', name: `EFT Total`, id: 'eft_total', required: true, step: '.01', md: 12 },
    { type: 'number', name: `On Account Total`, id: 'on_account_total', required: true, step: '.01', md: 12 },
    { type: 'number', name: `Counted Total`, id: 'counted_total', required: false, step: '.01', md: 12 },
  ]

  return countFormFields
}