import { useTranslation } from 'react-i18next';
import React from 'react';
import Select, { components } from 'react-select';
import { closestCenter, DndContext } from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { SortableContext, useSortable, } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';


const MultiValue = (props) => {
  const onMouseDown = event => {
    event.preventDefault();
    event.stopPropagation();
  };
  const innerProps = { ...props.innerProps, onMouseDown };
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: props.data.value,
    });
  const style = {
      transform: CSS.Translate.toString(transform),
      transition,
      width: '150px'
    };

  return (
    <div style={style} ref={setNodeRef} {...attributes} {...listeners}>
      <components.MultiValue {...props} innerProps={innerProps} />
    </div>
  );
};

const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove
      {...props}
      innerProps={{
        onPointerDown: (event) => event.stopPropagation(),
        ...props.innerProps,
      }}
    />
  );
};

function SortableMultiSelectField(props) {
  const { t: translate } = useTranslation();

  const selected = props.hasOwnProperty('selected') ? Array.from(props.selected) : []
  const selectedIds = selected.map((item) => item.value);

  const propOptions = props.hasOwnProperty('options') && props.options !== undefined ? props.options.hasOwnProperty('content') ? props.options.content : props.options : false

  let options = []
  if (propOptions) {
    // eslint-disable-next-line
    propOptions.map((option) => {
      options.push({ 'value': option.id, 'label': option.name || option.title || option.domain || option.id, 'name': props.name, 'prefix':props.prefix, 'id': props.id, 'seconds': option.seconds ? option.seconds : undefined})
    })
  }
  
  if (options) {
    return (
      <DndContext modifiers={[restrictToParentElement]} onDragEnd={props.onDragEnd} collisionDetection={closestCenter}>
        <SortableContext items={selectedIds}>
          <Select
            styles={props.MultiContainer ? {
              multiValue: (base) => ({
                ...base,
                display: 'grid',
                gridTemplateRows: '1fr 2fr',
                gridTemplateColumns: '10fr 1fr'
              }),
              multiValueLabel: (base) => ({
                ...base,
                width: '100%',
                gridRow: '1'
              }),
              multiValueRemove: (base) => ({
                ...base,
                gridRow: '1'
              })
            } : {
              multiValueLabel : (base) => ({
                ...base,
                width: '100%'
              })
            }}
            prefix={props.prefix}
            isMulti
            name={props.label}
            options={options}
            width={props.width}
            className={`basic-multi-select ${props.className}`}
            placeholder={props?.placeholder ? translate(props.placeholder) : translate('Select...')}
            classNamePrefix="select"
            onChange={props.onChange}
            value={selected}
            selectedViews={props.selectedViews}
            durationUpdate={props.durationUpdate}
            components={props.MultiContainer ? {
              MultiValue,
              MultiValueRemove,
              MultiValueContainer: props.MultiContainer,
            } : {
              MultiValue,
              MultiValueRemove
            }
            }
            isClearable={props.clearable}
            isSearchable={props.searchable}
            backspaceRemovesValue={false}
            required={props.required ?? true}
            closeMenuOnSelect={false}
            menuPortalTarget={document.querySelector('body')}
          />
        </SortableContext>
      </DndContext>
    );
  }
}

export default SortableMultiSelectField;