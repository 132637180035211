import { Table } from 'react-bootstrap';
import { Accordion } from 'react-bootstrap';

export default function OperatorsStatusReport(props) {
  const hashOperatorList = props.hashOperatorList
  const operatorsStatusReport = props.operatorsStatusReport
  
  if (!operatorsStatusReport?.users || Object.keys(operatorsStatusReport?.users).length === 0) return <p>No data</p>

  let accordions = []

  accordions.push(userAccordion(operatorsStatusReport, hashOperatorList))

  return accordions
}

function userAccordion(operatorsStatusReport, hashUserList) {
  let userRows = []
  for (const [userId, userData ] of Object.entries(operatorsStatusReport.users).sort(([a, adata], [b, bdata]) => bdata.total?.total - adata.total?.total)) {
    // // find this levels childs
    let dayRows = []
    dayRows.push(dayAccordion(userData.days))

    userRows.push(
      <Accordion flush>
        <Accordion.Item eventKey={userId}>
          <Accordion.Header className='journal-category-header'>
            <Table width='100%'>
              <thead>
                <tr>
                  <th width='60%'>{'Operator'}</th>
                  <th width='10%' className="text-end">{'Cash'}</th>
                  <th width='10%' className="text-end">{'EFT'}</th>
                  <th width='10%' className="text-end">{'On account'}</th>
                  <th width='10%' className="text-end">{'Total'}</th>
                </tr>
              </thead>
              <tbody>
                <tr key={userId}>
                  <td>{hashUserList[userId] ? hashUserList[userId].name : 'unknown'}</td>
                  <td align='right'>{(userData.total?.cash - userData.total?.exchange).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                  <td align='right'>{userData.total?.eft?.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                  <td align='right'>{userData.total?.on_account?.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                  <td align='right'>{userData.total?.total?.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                </tr>
              </tbody>
            </Table>
          </Accordion.Header>
          <Accordion.Body className='journal-category-body'>{dayRows}</Accordion.Body>
        </Accordion.Item>
      </Accordion>
    )
  }
  return userRows
}

function dayAccordion(days) {
  let dayRows = []

  for (const [date, dateData ] of Object.entries(days)) {
    if (/[0-9]{4}-[0-9]{2}-[0-9]{2}/i.test(date)) {
      dayRows.push(
        <Accordion flush>
          <Accordion.Item eventKey={date}>
            <Accordion.Header className='journal-category-header'>
              <Table width='100%'>
                <thead>
                  <tr>
                    <th width='60%'>{'Date'}</th>
                    <th width='10%' className="text-end">{'Cash'}</th>
                    <th width='10%' className="text-end">{'EFT'}</th>
                    <th width='10%' className="text-end">{'On account'}</th>
                    <th width='10%' className="text-end">{'Total'}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr key={date}>
                    <td>{date}</td>
                    <td align='right'>{(dateData.cash - dateData.exchange).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                    <td align='right'>{dateData.eft?.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                    <td align='right'>{dateData.on_account?.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                    <td align='right'>{dateData.total?.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                  </tr>
                </tbody>
              </Table>
            </Accordion.Header>
          </Accordion.Item>
        </Accordion>
      )
    }
  }
  return dayRows
}