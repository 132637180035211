import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getProducts, setProducts, getProductPagesize, getProductPagination, getSelectedProducts, getProductFilter, getProductTablePriceEditable, setProductTablePriceEditable } from "../../redux/dataSlices/productSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { getTemplatesAll, setTemplatesAll } from "../../redux/dataSlices/templateSlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import BasicCard from "../../components/card"
import { getSettingsSchapkaartjes, setSettingsSchapkaartjes } from "../../redux/dataSlices/settingsSlice";
import { getProgressModalShow, setProgressModalShow } from "../../redux/dataSlices/analyseSlice";
import ProgressModal from "../../components/progressModal";
import GetAllTemplates from "../../actablueAPI/shelflabels/GetAllTemplates";
import GetAllPrinters from "../../actablueAPI/shelflabels/GetAllPrinters";
import { setPrintersAll } from "../../redux/dataSlices/printerSlice";
import GetAllCategoriesWithType from "../../actablueAPI/products/getAllCategoriesWithType";
import { getHashJournalCategoriesAll, getJournalCategoriesAll, setJournalCategoriesAll } from "../../redux/dataSlices/categorySlice";
import { getHashVatsAll, getVatsAll, setVatsAll } from "../../redux/dataSlices/vatSlice";
import GetAllVats from "../../helpers/getAllVats";
import GetSettings from "../../actablueAPI/shelflabels/GetSettings";
import { setStartDate, getStartDate } from "../../redux/dataSlices/reportingDateSlice";
import moment from "moment"
import Datepicker from "../../components/datepicker";
import TableButton from "../../components/tableButton";
import PostProductsModified from "../../actablueAPI/products/PostProductsModified";
import ProductModifiedTable from "../../components/productsModifiedTable";
import { MultiEditFormData } from "../../helpers/multiEditProducts/multiEditFormData";
import useHandleError from "../../customhooks/useHandleError";
import SearchAndMultiFilterSingleSelect from "../../components/searchAndMultiFilterSingleSelectField";

const ProductsModified = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const journalCategories = useSelector(getJournalCategoriesAll)
  const hash_journal_categories = useSelector(getHashJournalCategoriesAll)
  const vats_all = useSelector(getVatsAll)
  const hash_vats_all = useSelector(getHashVatsAll)
  const products = useSelector(getProducts);
  const selectedProducts = useSelector(getSelectedProducts)
  const templates = useSelector(getTemplatesAll);
  const pagination = useSelector(getProductPagination)
  const pageSize = useSelector(getProductPagesize)
  const settings = useSelector(getSettingsSchapkaartjes)
  const scopes = useSelector(getScopes)
  const modal_show = useSelector(getProgressModalShow)
  const filter = useSelector(getProductFilter)
  const startDate = useSelector(getStartDate)
  const dispatch = useDispatch();
  const HandleError = useHandleError();
  const [refresh, setRefresh] = useState(false)
  const [multiEditData, setMultiEditData] = useState({})
  const tableEditable = useSelector(getProductTablePriceEditable);
  
  const navTitle = "Productsmodified"

  function toggleRefresh() {
    setRefresh(!refresh)
  }

  const onDatePickerChange = (start, end) => {
    dispatch(setStartDate(start.startOf('day').unix()))
  }

  const datePicker = {
    startDate: moment.unix(startDate),
    endDate: moment.unix(startDate),
    locale: {
      format: "DD/MM/YYYY",
    },
    alwaysShowCalendars: true,
    showDropdowns: true,
    opens: 'right',
    linkedCalendars: false,
    autoApply: true,
    singleDatePicker: true,
    onChange: onDatePickerChange
  }

  const refreshButton = {
    tag: "Refresh",
    value: 'refresh',
    onClick: toggleRefresh,
    className: 'add-button'
  }

  const multiEditFormData = MultiEditFormData({
    token:token,
    admin_id: admin_id,
    journalCategories:journalCategories,
    hash_journal_categories:hash_journal_categories,
    vats_all: vats_all,
    hash_vats_all: hash_vats_all,
    selectedProducts: selectedProducts,
    multiEditData: multiEditData,
    dispatch:dispatch,
    setMultiEditData: setMultiEditData,
    toggleRefresh: toggleRefresh
  })

  const onTableEditableClick = () => {
    dispatch(setProductTablePriceEditable(!tableEditable));
  }

  const searchAndFilter = {
    settings: {
      minimal: {
        label: 'Price Editable in Table',
        onClick: onTableEditableClick,
        value: tableEditable || false
      }
    }
  }

  const productsCard = {
    size: 12,
    title: navTitle,
    subtitle: <><Datepicker {...datePicker} /><TableButton {...refreshButton} /></>,
    data: <ProductModifiedTable products={products} templates={templates} settings={settings} multiEdit={multiEditFormData} refresh={toggleRefresh}/>,
  };

  const onViewHide = () => {
    dispatch(setProgressModalShow(false));
  };

  const progressModal = {
    modal: {
      show: modal_show,
      title: 'Processing...',
      onHide: onViewHide
    }
  }

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (admin_id) {
      getDataFromApi()
      dispatch(setLoading(true))
      PostProductsModified({token:token, admin_id:admin_id, location_id:location_id, page:pagination, size:pageSize, data:{'date':startDate}})
      .then((response) => {
        dispatch(setProducts(response.data))
        dispatch(setLoading(false))
      })
      .catch((error) => {
        HandleError({error})
        dispatch(setLoading(false))
      })
    }
    // eslint-disable-next-line
  }, [admin_id, location_id, filter, pagination, pageSize, refresh, startDate]);

  async function getDataFromApi() {
    if (!journalCategories || journalCategories?.length <= 0) {
      await GetAllCategoriesWithType({ type: 'JOURNAL', token: token, admin_id: admin_id, location_id: location_id })
      .then(async (response) => {dispatch(setJournalCategoriesAll(response))})
    }
    
    if (!vats_all || vats_all?.length <= 0) {
      await GetAllVats({ token: token, admin_id: admin_id, location_id: location_id })
      .then(async (response) => {dispatch(setVatsAll(response))})
    }
  
    if (location_id && scopes.schapkaartjes) {
      await GetAllTemplates({ token: token, admin_id: admin_id, location_id: location_id, base_labels: true, sorted: true, page: 1, size: 25 })
      .then(response => {dispatch(setTemplatesAll(response))})
      .catch(error => {HandleError({ error: error })})
  
      await GetAllPrinters({ token: token, admin_id: admin_id, location_id: location_id })
      .then(response => {dispatch(setPrintersAll(response))})
      .catch(error => {HandleError({ error: error })})
  
      await GetSettings({token:token, admin_id:admin_id, location_id:location_id})
      .then(response => {dispatch(setSettingsSchapkaartjes(response.data))})
      .catch(error => {HandleError({ error: error })})
    }
  }

  return (
    <>
      <script type="text/javascript" src="https://cdn.jsdelivr.net/jquery/latest/jquery.min.js"></script>
      <script type="text/javascript" src="https://cdn.jsdelivr.net/momentjs/latest/moment.min.js"></script>
      <script type="text/javascript" src="https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.min.js"></script>
      <link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.css" />
      <ProgressModal {...progressModal} />
      {location_id && <SearchAndMultiFilterSingleSelect {...searchAndFilter} />}
      <BasicCard {...productsCard} />
    </>
  );
};
export default ProductsModified;