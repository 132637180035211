import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import { DrawerFormFields } from "./drawerFormFields";
import BasicCard from "../../components/card"
import HandleOnChange from "../../helpers/handleOnChange";
import DrawerForm from "../../components/drawerForm";
import { countAsChoices } from "../../selectfieldchoices/cashcountselect.mjs";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import PostCashCountDrawer from "../../actablueAPI/cashcount/PostCashCountDrawer";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import axios from "axios";
import GetEmployees from "../../actablueAPI/employee/GetEmployees";
import { getEmployeesAll, setEmployeesAll } from "../../redux/dataSlices/employeeSlice";
import { getDevicesAll, getHashDevicesAll, setDevicesAll } from "../../redux/dataSlices/deviceSlice";
import useHandleError from "../../customhooks/useHandleError";
import GetDevicesSmartpos from "../../actablueAPI/devices/GetDevicesSmartpos";

const DrawerAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const employeeAll = useSelector(getEmployeesAll);
  const devicesAll = useSelector(getDevicesAll);
  const hashDevicesAll = useSelector(getHashDevicesAll);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [drawer, setDrawer] = useState({ admin_id: admin_id, location_id: location_id, count_as: "Device" });
  const HandleError = useHandleError();

  const onChange = (event) => {
    let newDrawer = HandleOnChange({ event: event, object: drawer })
    setDrawer(newDrawer)
  }

  const onChangeEmployee = (event) => {
    let newDrawer = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: drawer, selectfield: 'employee_id' })
    setDrawer(newDrawer)
  }

  const onChangeDevice = (event) => {
    let newDrawer = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: drawer, selectfield: 'device_id' })
    newDrawer.device_id = hashDevicesAll?.[newDrawer.device_id]?.uid || null
    setDrawer(newDrawer)
  }

  const onChangeCountAs = (event) => {
    let newDrawer = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: drawer, selectfield: 'count_as' })
    setDrawer(newDrawer)
  }

  const onCancel = () => { navigate(-1) }

  const onSubmit = (event) => {

    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    PostCashCountDrawer({ token: token, data: drawer })
      .then(() => {
        navigate("/cashcount");
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: DrawerFormFields(),
    field: {
      onChange: onChange
    },
    data: drawer,
    count_as: {
      name: 'count_as',
      options: countAsChoices,
      selected: [],
      onChange: onChangeCountAs,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    employee_id: {
      name: 'employee_id',
      options: employeeAll,
      selected: [],
      onChange: onChangeEmployee,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    device_id: {
      name: 'device_id',
      options: devicesAll,
      selected: [],
      onChange: onChangeDevice,
      clearable: Boolean(true),
      searchable: Boolean(true)
    }
  };

  const addCard = {
    size: 12,
    title: "Drawer add",
    data: <DrawerForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Drawer add"))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    axios.all([
      GetEmployees({ token: token, admin_id: admin_id }),
      GetDevicesSmartpos({ token: token, admin_id: admin_id })
    ])
      .then(axios.spread((employeeData, devicesData) => {
        dispatch(setLoading(false))
        dispatch(setEmployeesAll(employeeData.data))
        dispatch(setDevicesAll(devicesData.data))
      }))
      .catch(error => {
        HandleError({ error: error })
        navigate("/cashcount");
      });
    // eslint-disable-next-line
  }, [admin_id, location_id])

  return (
    <>
      <BasicCard {...addCard} />
    </>
  )

};



export default DrawerAdd;

