import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getAttributeGroupsAll, getHashAttributeGroupsAll, setAttributeGroupsAll } from "../../redux/dataSlices/attributeSlice";
import { getVatsAll, setVatsAll, getHashVatsAll } from "../../redux/dataSlices/vatSlice";
import { getProduct, setProduct } from "../../redux/dataSlices/productSlice";
import { getProductCategoriesAll, getHashProductCategoriesAll, getJournalCategoriesAll, getHashJournalCategoriesAll, setJournalCategoriesAll, setProductCategoriesAll } from "../../redux/dataSlices/categorySlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import ProductForm from "../../components/productForm"
import { ProductFormFields, ProductSupplierFormFields } from "./productFormFields.jsx"
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeJournalCategory from "../../helpers/handleOnChangeJournalCategory";
import HandleOnChangeProductCategory from "../../helpers/handleOnChangeProductCategory";
import HandleOnChangeSingleSelectFieldWithObject from "../../helpers/handleOnChangeSingleSelectFieldWithObject";
import HandleOnChangeMultiObjectField from "../../helpers/handleOnChangeMultiObjectFields";
import AddObject from "../../helpers/addObject";
import RemoveObject from "../../helpers/removeObject";
import HandleOnChangeCheckbox from "../../helpers/handleOnChangeCheckbox";
import GetAllVats from "../../helpers/getAllVats";
import HandleOnChangeMultiSelectFieldWithValues from "../../helpers/handleOnChangeMultiSelectFieldWithValues";
import HandleOnChangeMultiSelectFieldWithObjects from "../../helpers/handleOnChangeMultiSelectFieldWithObjects";
import HandleOnChangeEans from "../../helpers/handleOnChangeEans";
import GetAllCategoriesWithType from "../../actablueAPI/products/getAllCategoriesWithType.jsx";
import { productFilterChoices } from "../../selectfieldchoices/productselect.mjs"
import { codeTypeChoices } from "../../selectfieldchoices/producteslselect.mjs";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import { getTemplatesAll } from "../../redux/dataSlices/templateSlice";
import { validateFormObject } from "../../helpers/validateFormObject.jsx";
import { setActiveTab } from "../../redux/dataSlices/formTabSlice.js";
import PostProductLinkOpticon from "../../actablueAPI/opticon/PostProductLink.jsx";
import PostProductLinkSchap from "../../actablueAPI/shelflabels/PostProductLink.jsx";
import PostProductSupplier from "../../actablueAPI/products/PostProductSupplier.jsx";
import DeleteProductSupplier from "../../actablueAPI/products/DeleteProductSupplier.jsx";
import DeleteEan from "../../actablueAPI/products/DeleteEan.jsx";
import GetAllPrinters from "../../actablueAPI/kitchenmanager/GetAllPrinters.jsx";
import { getHashPrintersAll, getHashScreensAll, getHashSortCategoriesAll, getPrintersAll, getScreensAll, getSortCategoriesAll, setPrintersAll, setScreensAll, setSortCategoriesAll } from "../../redux/dataSlices/kitchenmanagerSlice.js";
import GetAllCategories from "../../actablueAPI/kitchenmanager/GetAllCategories.jsx";
import DeleteKitchenManagerProduct from "../../actablueAPI/kitchenmanager/DeleteKitchenManagerProduct.jsx";
import GetAllAttributegroups from "../../actablueAPI/products/GetAllAttributegroups.jsx";
import HandleOnChangeMultiStringArray from "../../helpers/handleOnChangeMultiStringArray.jsx";
import DelProductIdFromCategory from "../../actablueAPI/products/DelProductIdFromCategory.jsx";
import PostKitchenManagerProduct from "../../actablueAPI/kitchenmanager/PostKitchenManagerProduct.jsx";
import PostProduct from "../../actablueAPI/products/PostProduct.jsx";
import PostProductSpecifications from "../../actablueAPI/products/PostProductSpecifications.jsx";
import GetKitchenmanagerProduct from "../../actablueAPI/kitchenmanager/GetKitchenManagerProduct.jsx";
import GetAllScreens from "../../actablueAPI/kitchenmanager/GetAllScreens.jsx";
import useHandleError from "../../customhooks/useHandleError.jsx";
import GetOpticonProductLink from "../../actablueAPI/opticon/GetProductLink.jsx";
import GetShelflabelsProductLink from "../../actablueAPI/shelflabels/GetProductLink.jsx";
import GetRelationsByTypeAll from "../../actablueAPI/relations/GetRelationsByTypeAll.jsx";
import { setRelationsAll } from "../../redux/dataSlices/relationSlice.js";

const ProductEdit = () => {
  const token = useSelector(getToken);
  const scopes = useSelector(getScopes);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const product = useSelector(getProduct)
  const journalCategories = useSelector(getJournalCategoriesAll)
  const hash_journal_categories = useSelector(getHashJournalCategoriesAll)
  const productpageCategories = useSelector(getProductCategoriesAll)
  const hash_productpage_categories = useSelector(getHashProductCategoriesAll)
  const vats_all = useSelector(getVatsAll)
  const hash_vats_all = useSelector(getHashVatsAll)
  const attributeGroups = useSelector(getAttributeGroupsAll)
  const hash_attributegroups = useSelector(getHashAttributeGroupsAll)
  const templates = useSelector(getTemplatesAll)
  const kmPrinters = useSelector(getPrintersAll);
  const hash_printers = useSelector(getHashPrintersAll);
  const sortCategories = useSelector(getSortCategoriesAll);
  const hash_sort_categories = useSelector(getHashSortCategoriesAll);
  const screens = useSelector(getScreensAll);
  const hash_screens = useSelector(getHashScreensAll);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HandleError = useHandleError();
  const uniqueSKU = `ACTA${Date.now()}`
  const [deleteCategories, setDeleteCategories] = useState([])
  const [checkedList, setCheckedList] = useState([])
  const [invalidFields, setInvalidFields] = useState([])
  const [supplierInvalidFields, setSupplierInvalidFields] = useState([])
  const [suppliers, setSuppliers] = useState([])
  const [supplier, setSupplier] = useState({ admin_id: admin_id })
  const [originalSuppliers, setOriginalSuppliers] = useState([]);
  const [supplierModalShow, setSupplierModalShow] = useState(false)
  const [relationLoading, setRelationLoading] = useState(false)
  const [id, setId] = useState(-1000)
  const navTitle = "Product edit"

  const onCancel = () => { navigate(-1) }

  const onSubmit = async () => {
    const [invalid] = validateFormObject({ object: product, fields: formData?.fields, switchTab: true, dispatch: dispatch })

    if (invalid?.length > 0) {
      setInvalidFields(invalid)
      return;
    }

    dispatch(setLoading(true))

    let newProduct = JSON.parse(JSON.stringify(product))

    if (newProduct?.suppliers) {
      for (const productSupplier of originalSuppliers) {
        if (!(productSupplier?.id in suppliers)) await DeleteProductSupplier({ token: token, id: productSupplier?.id, admin_id: admin_id })
        if (productSupplier?.eans) {
          for (const ean of productSupplier?.eans) {
            let eansToDelete = []
            if (!(suppliers[productSupplier?.id]?.eans?.find(findEan => findEan?.id === ean?.id))) eansToDelete?.push(productSupplier?.eans?.find(findEan => findEan?.id === ean?.id))
            for (const deleteEan of eansToDelete) {
              await DeleteEan({ token: token, id: deleteEan?.id, admin_id: admin_id })
            }
          }
        }
      }
      delete newProduct.suppliers
    }

    if (suppliers) {
      suppliers.forEach(async (supplier) => {
        let newSupplier = JSON.parse(JSON.stringify(supplier))
        newSupplier.product = { 'id': product?.id };
        if (newSupplier?.temp) {
          delete newSupplier?.id
          delete newSupplier?.temp
        }
        await PostProductSupplier({ token: token, admin_id: admin_id, data: newSupplier })
      })
    }

    // delete product id from categories
    deleteCategories.forEach(async category => {
      await DelProductIdFromCategory({ token: token, admin_id: admin_id, category_id: category.id, product_id: product.id })
        .then(() => {
          delete deleteCategories[category]
        })
    });


    if (newProduct.hasOwnProperty('esl') && Object.keys(newProduct?.esl).length > 0) {
      if (scopes.schapkaartjes) {
        const opticonData =
        {
          'location_id': location_id,
          'product_id': product.id,
          'product_name': product.name,
          'esl_barcode': product.esl.esl_barcode
        }

        const schapData =
        {
          'product_id': product.id,
          'print_template_id': product.esl.print_template_id,
          'esl_template_id': product.esl.esl_template_id,
          'code_type': product.esl.code_type,
          'admin_id': admin_id,
          'location_id': location_id
        }

        if (opticonData?.esl_barcode) {
          await PostProductLinkOpticon({ token: token, data: opticonData })
            .catch(error => {
              console.error(error)
            });
        }

        if (schapData?.product_id) {
          await PostProductLinkSchap({ token: token, data: schapData })
            .catch(error => {
              console.error(error)
            });
        }

        delete newProduct.esl
      }
    }

    if (newProduct.hasOwnProperty('kitchenmanager') && Object.keys(newProduct?.kitchenmanager).length > 0) {

      if (scopes.sortcategories) {
        if ((newProduct?.kitchenmanager?.printer && newProduct?.kitchenmanager?.category) ||
          (newProduct?.kitchenmanager?.screens && newProduct?.kitchenmanager?.category) ||
          (newProduct?.kitchenmanager?.printer && newProduct?.kitchenmanager?.category && newProduct?.kitchenmanager?.screens)) {
          newProduct.kitchenmanager.has_multiple_groups = newProduct.categories.filter(item => item.type === 'PRODUCTPAGE').length > 1;
          newProduct.kitchenmanager.product_id = newProduct?.id;
          newProduct.kitchenmanager.name = newProduct?.name;
          newProduct.kitchenmanager.admin_id = admin_id;
          newProduct.kitchenmanager.location_id = location_id;
          if (!newProduct?.kitchenmanager?.screens) newProduct.kitchenmanager.screens = [];
          await PostKitchenManagerProduct({ token: token, data: newProduct?.kitchenmanager })
            .catch(error => {
              console.error(error)
            });
        } else {
          await DeleteKitchenManagerProduct({ token: token, id: newProduct?.id, admin_id: admin_id, location_id: location_id })
            .catch(error => {
              console.error(error)
            });
        }

        delete newProduct.kitchenmanager
      }
    }

    // post product
    await PostProduct({ token: token, admin_id: admin_id, data: newProduct })

    // post poductspecifications
    if (product.hasOwnProperty('product_specifications')) {
      await PostProductSpecifications({ token: token, admin_id: admin_id, id: newProduct.id, data: newProduct.product_specifications })
    }

    // navigate("/products");
    navigate(-1)
  };

  const onAddObject = (event) => {
    let newId = id + 1
    setId(newId)
    let newProduct = AddObject({ event: event, object: product, id: newId })
    dispatch(setProduct(newProduct))
  }

  const onRemoveObjects = (event) => {
    let newProduct = RemoveObject({ event: event, object: product, checkedList: checkedList })
    dispatch(setProduct(newProduct))
    setCheckedList([])
  }

  const onRemoveObjectsSuppliers = (event) => {
    let newProduct = RemoveObject({ event: event, object: product, checkedList: checkedList })
    setSuppliers(newProduct?.suppliers)
    dispatch(setProduct(newProduct))
    setCheckedList([])
  }

  const onChangeCheckbox = (event) => {
    let newCheckedList = HandleOnChangeCheckbox({ event: event, object: checkedList })
    setCheckedList(newCheckedList)
  }

  const onChangeAllCheckbox = (event) => {
    // const checked = event.target?.checked
    // const id = event.target?.attributes?.id?.nodeValue
    // let newCheckedList = Object.assign([], checkedList)
    // if (checked) {newCheckedList.push(id)}
    // if (!checked) {newCheckedList.pop(id)}
    // setCheckedList(newCheckedList)
  }

  const onChange = (event) => {
    let newProduct = HandleOnChange({ event: event, object: product, admin_id: admin_id })
    dispatch(setProduct(newProduct))
  }

  const onJournalCategoriesChange = (event) => {
    let [newProduct, newDeleteCategories] = HandleOnChangeJournalCategory({ event: event, object: product, hash: hash_journal_categories, deleteList: deleteCategories })
    dispatch(setProduct(newProduct))
    setDeleteCategories(newDeleteCategories)
  };

  const onProductCategoriesChange = (event) => {
    let [newProduct, newDeleteCategories] = HandleOnChangeProductCategory({ event: event, object: product, hash: hash_productpage_categories, deleteList: deleteCategories })
    dispatch(setProduct(newProduct))
    setDeleteCategories(newDeleteCategories)
  };

  const onVatChange = (event) => {
    let newProduct = HandleOnChangeSingleSelectFieldWithObject({ event: event, object: product, selectfield: 'vat', hash: hash_vats_all })
    dispatch(setProduct(newProduct))
  };

  const onEanChange = (event) => {
    let newProduct = HandleOnChangeEans({ event: event, object: product })
    dispatch(setProduct(newProduct))
  };

  const onImageChange = (event) => {
    let newProduct = HandleOnChangeMultiSelectFieldWithValues({ event: event, object: product, selectfield: 'images' })
    dispatch(setProduct(newProduct))
  };

  const onAttributeGroupChange = (event) => {
    let newProduct = HandleOnChangeMultiSelectFieldWithObjects({ event: event, object: product, selectfield: 'attributes', hash: hash_attributegroups })
    dispatch(setProduct(newProduct))
  };

  const onTypeChange = (event) => {
    let newProduct = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: product, selectfield: 'type' })
    dispatch(setProduct(newProduct))
  }

  const onTemplateChange = (event) => {
    let newProduct = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: product, selectfield: 'esl_template_id', prefix: 'esl' })
    dispatch(setProduct(newProduct))
  }

  const onPrintTemplateChange = (event) => {
    let newProduct = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: product, selectfield: 'print_template_id', prefix: 'esl' })
    dispatch(setProduct(newProduct))
  }

  const onCodeChange = (event) => {
    let newProduct = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: product, selectfield: 'code_type', prefix: 'esl' })
    dispatch(setProduct(newProduct))
  }

  const onChangeNutrient = (event) => {
    let newProduct = HandleOnChangeMultiObjectField({ event: event, object: product, field: 'nutrients' })
    dispatch(setProduct(newProduct))
  }

  const toggleSupplierModal = () => {
    setSupplierModalShow(!supplierModalShow)
    setSupplier({ admin_id: admin_id });
  }

  const onPrinterChange = (event) => {
    let newProduct = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: product, selectfield: 'printer', prefix: 'kitchenmanager' })
    dispatch(setProduct(newProduct))
  }

  const onCategoryChange = (event) => {
    let newProduct = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: product, selectfield: 'category', prefix: 'kitchenmanager' })
    dispatch(setProduct(newProduct))
  }

  const onScreensChange = (event) => {
    let newProduct = HandleOnChangeMultiSelectFieldWithValues({ event: event, object: product, selectfield: 'screens', prefix: 'kitchenmanager' })
    if (newProduct.kitchenmanager.screens.length === 0) {
      delete newProduct.kitchenmanager.screens
    }
    dispatch(setProduct(newProduct))
  }

  const onChangeKitchenManager = (event) => {
    let newProduct = HandleOnChange({ event: event, object: product, admin_id: admin_id, prefix: 'kitchenmanager' })
    dispatch(setProduct(newProduct))
  }

  const saveSupplier = () => {
    const [invalid] = validateFormObject({ object: supplier, fields: formData?.suppliers?.modal?.fields, switchTab: false, dispatch: dispatch })

    if (invalid?.length > 0) {
      setSupplierInvalidFields(invalid)
      return;
    }

    let newProduct = JSON.parse(JSON.stringify(product))
    let newSuppliers = JSON.parse(JSON.stringify(suppliers));
    if (!supplier?.id) {
      let newId = id + 1;
      setId(newId);
      supplier.id = id
      supplier.temp = true
    }
    const existingIndex = newSuppliers?.findIndex(existingSupplier => existingSupplier?.id === supplier?.id)
    if (existingIndex === -1) newSuppliers.push(supplier);
    if (existingIndex !== -1) newSuppliers[existingIndex] = supplier
    newProduct.suppliers = newSuppliers
    dispatch(setProduct(newProduct));
    setSuppliers(newSuppliers);
    setSupplierModalShow(false);
    setSupplierInvalidFields([]);
    setSupplier({ admin_id: admin_id });
  }

  const onChangeSupplier = (event) => {
    let newSupplier = HandleOnChange({ event: event, object: supplier })
    setSupplier(newSupplier);
  }

  const onChangeSupplierSingleSelect = (event) => {
    let newSupplier = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: supplier, selectfield: 'relation_id' })
    setSupplier(newSupplier)
  }

  const onSupplierEanChange = (event) => {
    let newSupplier = HandleOnChangeEans({ event: event, object: supplier })
    setSupplier(newSupplier)
  };

  const onSupplierAdd = () => {
    setSupplierModalShow(true);
  }

  const onSupplierEdit = (event, id) => {
    event.stopPropagation();
    setSupplier(suppliers?.find(supplier => supplier?.id === id));
    setSupplierModalShow(true);
  }

  const onChangeAllergenics = (event) => {
    let newProduct = HandleOnChangeMultiStringArray({ event: event, object: product, field: 'allergenics', change: 'change' })
    dispatch(setProduct(newProduct))
  }

  const onAddAllergenicsString = (event) => {
    let newProduct = HandleOnChangeMultiStringArray({ event: event, object: product, field: 'allergenics', change: 'add' })
    dispatch(setProduct(newProduct))
  }

  const onRemoveAllergenicsString = (event) => {
    let newProduct = HandleOnChangeMultiStringArray({ event: event, object: product, field: 'allergenics', change: 'remove', checkedList: checkedList })
    dispatch(setProduct(newProduct))
  }

  const onChangeIngredients = (event) => {
    let newProduct = HandleOnChangeMultiStringArray({ event: event, object: product, field: 'ingredients', change: 'change' })
    dispatch(setProduct(newProduct))
  }

  const onAddIngredientsString = (event) => {
    let newProduct = HandleOnChangeMultiStringArray({ event: event, object: product, field: 'ingredients', change: 'add' })
    dispatch(setProduct(newProduct))
  }

  const onRemoveIngredientsString = (event) => {
    let newProduct = HandleOnChangeMultiStringArray({ event: event, object: product, field: 'ingredients', change: 'remove', checkedList: checkedList })
    dispatch(setProduct(newProduct))
  }

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    invalidFields: invalidFields,
    fields: ProductFormFields(),
    field: {
      onChange: onChange
    },
    data: product,
    templates: templates,
    hash_printers: hash_printers,
    hash_sort_categories: hash_sort_categories,
    hash_screens: hash_screens,
    journalcategories: {
      name: 'journalcategories',
      options: journalCategories,
      selected: {},
      onChange: onJournalCategoriesChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    productcategories: {
      name: 'productcategories',
      options: productpageCategories,
      selected: new Set(),
      onChange: onProductCategoriesChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    vats: {
      label: 'vat',
      options: vats_all,
      onChange: onVatChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    eans: {
      label: 'eans',
      options: [],
      selected: [],
      onChange: onEanChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    images: {
      label: 'images',
      options: [],
      selected: [],
      onChange: onImageChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    attributes: {
      label: 'attributes',
      options: attributeGroups,
      selected: [],
      onChange: onAttributeGroupChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    type: {
      label: 'type',
      options: productFilterChoices,
      selected: [],
      onChange: onTypeChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    print_template_id: {
      label: 'print_template_id',
      options: [],
      selected: [],
      onChange: onPrintTemplateChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    esl_template_id: {
      label: 'esl_template_id',
      options: [],
      selected: [],
      onChange: onTemplateChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    printer: {
      label: 'printer',
      options: kmPrinters,
      selected: [],
      onChange: onPrinterChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    category: {
      label: 'category',
      options: sortCategories,
      selected: [],
      onChange: onCategoryChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    screens: {
      label: 'screens',
      options: screens,
      selected: [],
      onChange: onScreensChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    code_type: {
      label: 'code_type',
      options: codeTypeChoices,
      selected: [],
      onChange: onCodeChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    short_name: {
      onChange: onChangeKitchenManager
    },
    nutrients: {
      onChange: onChangeNutrient,
      add: {
        title: 'Add',
        type: 'button',
        name: 'nutrients',
        onClick: onAddObject
      },
      remove: {
        title: 'Remove',
        type: 'button',
        name: 'nutrients',
        onClick: onRemoveObjects
      },
      check: {
        onChange: onChangeCheckbox,
        onChangeAll: onChangeAllCheckbox
      },
      selected: checkedList
    },
    allergenics: {
      onChange: onChangeAllergenics,
      add: {
        title: 'Add',
        type: 'button',
        name: 'allergenics',
        onClick: onAddAllergenicsString
      },
      remove: {
        title: 'Remove',
        type: 'button',
        name: 'allergenics',
        onClick: onRemoveAllergenicsString
      },
      check: {
        onChange: onChangeCheckbox,
        onChangeAll: onChangeAllCheckbox
      },
      selected: checkedList
    },
    ingredients: {
      onChange: onChangeIngredients,
      add: {
        title: 'Add',
        type: 'button',
        name: 'ingredients',
        onClick: onAddIngredientsString
      },
      remove: {
        title: 'Remove',
        type: 'button',
        name: 'ingredients',
        onClick: onRemoveIngredientsString
      },
      check: {
        onChange: onChangeCheckbox,
        onChangeAll: onChangeAllCheckbox
      },
      selected: checkedList
    },
    suppliers: {
      data: suppliers,
      add: {
        title: 'Add',
        type: 'button',
        name: 'suppliers',
        onClick: onSupplierAdd
      },
      remove: {
        title: 'Remove',
        type: 'button',
        name: 'suppliers',
        onClick: onRemoveObjectsSuppliers
      },
      edit: {
        tag: "Edit",
        value: 'edit',
        onClick: onSupplierEdit
      },
      check: {
        onChange: onChangeCheckbox,
        onChangeAll: onChangeAllCheckbox
      },
      modal: {
        title: 'Product Supplier',
        data: supplier,
        show: supplierModalShow,
        toggleModal: toggleSupplierModal,
        setRelationLoading: setRelationLoading,
        onSaveClick: saveSupplier,
        fields: ProductSupplierFormFields(),
        field: {
          onChange: onChangeSupplier
        },
        invalidFields: supplierInvalidFields,
        relation_id: {
          label: 'relation_id',
          options: [],
          selected: [],
          onChange: onChangeSupplierSingleSelect,
          clearable: Boolean(true),
          searchable: Boolean(true),
          placeholder: relationLoading ? 'Loading relations...' : 'Select...',
          disabled: relationLoading,
          loading: relationLoading
        },
        eans: {
          label: 'eans',
          options: [],
          selected: [],
          onChange: onSupplierEanChange,
          clearable: Boolean(true),
          searchable: Boolean(true)
        },
      },
      selected: checkedList
    }
  };

  const productEditCard = {
    size: 12,
    title: navTitle,
    data: <ProductForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    dispatch(setActiveTab('main'));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    getDataFromApi()
      .finally(() => {
        dispatch(setLoading(false))
      })
    // eslint-disable-next-line
  }, []);

  async function getDataFromApi() {
    return new Promise(async (resolve) => {
      if (!product.sku_code) {
        let newProduct = JSON.parse(JSON.stringify(product))
        newProduct.sku_code = uniqueSKU
        dispatch(setProduct(newProduct))
      }
      if (!journalCategories || journalCategories?.length <= 0) {
        await GetAllCategoriesWithType({ type: 'JOURNAL', token: token, admin_id: admin_id, location_id: location_id })
          .then(async (response) => { dispatch(setJournalCategoriesAll(response)) })
          .catch(error => { HandleError({ error: error }) });
      }

      if (!productpageCategories || productpageCategories?.length <= 0) {
        await GetAllCategoriesWithType({ type: 'PRODUCTPAGE', token: token, admin_id: admin_id, location_id: location_id })
          .then(async (response) => { dispatch(setProductCategoriesAll(response)) })
          .catch(error => { HandleError({ error: error }) });
      }

      if (!vats_all || vats_all?.length <= 0) {
        await GetAllVats({ token: token, admin_id: admin_id, location_id: location_id })
          .then(async (response) => { dispatch(setVatsAll(response)) })
          .catch(error => { HandleError({ error: error }) });
      }

      if (!attributeGroups || attributeGroups?.length <= 0) {
        await GetAllAttributegroups({ token: token, admin_id: admin_id, location_id: location_id })
          .then(async (response) => { dispatch(setAttributeGroupsAll(response)) })
          .catch(error => { HandleError({ error: error }) });
      }


      if (scopes?.schapkaartjes) {
        let newProduct = JSON.parse(JSON.stringify(product))
        await GetOpticonProductLink({ token: token, product_id: product.id })
          .then(response => {
            newProduct.esl = response.data || {}
          })
          .catch(error => {
            if (error?.response?.status !== 500) HandleError({ error: `(Opticon) ${error}` })
          });

        await GetShelflabelsProductLink({ token: token, product_id: product.id, admin_id: admin_id, location_id: location_id })
          .then(response => {
            newProduct.esl = Object.assign({}, newProduct.esl || {}, response.data)
          })
          .catch(error => {
            if (error?.response?.status !== 404) HandleError({ error: `(Shelflabels) ${error}` })
          });

        dispatch(setProduct(newProduct))
      }

      if (scopes?.sortcategories) {
        await GetKitchenmanagerProduct({ token: token, id: product?.id, admin_id: admin_id, location_id: location_id })
          .then(response => {
            let newProduct = JSON.parse(JSON.stringify(product))
            newProduct.kitchenmanager = response.data;
            if (response.data?.printer) newProduct.kitchenmanager.printer = response.data?.printer?.id;
            if (response.data?.category) newProduct.kitchenmanager.category = response.data?.category?.id;
            dispatch(setProduct(newProduct))
          })
          .catch(error => {
            if (error?.response?.status !== 404) HandleError({ error: `(Kitchen Manager) ${error}` })
          });

        await GetAllPrinters({ token: token, admin_id: admin_id, location_id: location_id })
          .then(response => {
            dispatch(setPrintersAll(response))
          })
          .catch(error => { HandleError({ error: `(Kitchen Manager) ${error}` }) });

        await GetAllCategories({ token: token, admin_id: admin_id, location_id: location_id })
          .then(response => {
            dispatch(setSortCategoriesAll(response))
          })
          .catch(error => { HandleError({ error: `(Kitchen Manager) ${error}` }) });

        await GetAllScreens({ token: token, admin_id: admin_id, location_id: location_id })
          .then(response => {
            dispatch(setScreensAll(response))
          })
          .catch(error => { HandleError({ error: `(Kitchen Manager) ${error}` }) });
      }

      await GetRelationsByTypeAll({ token: token, admin_id: admin_id, type: 'SUPPLIER' })
        .then(response => {
          dispatch(setRelationsAll(response));
        })
        .catch(error => {
          HandleError({ error: error })
        })
        .finally(() => {
          setSuppliers(product?.suppliers || []);
          setOriginalSuppliers(product?.suppliers || []);
          setRelationLoading(false);
        })

      resolve(true)
    })
  }

  return (
    <>
      <BasicCard {...productEditCard} />
    </>
  )

};
export default ProductEdit;